import classNames from 'classnames';
import { ReactNode } from 'react';


interface LinkButtonProps {
    style?: React.CSSProperties;
    onClick: (e: React.MouseEvent) => void;
    children: ReactNode;
    disabled?: boolean;
}

export default function LinkButton({
    style,
    onClick,
    children,
    disabled,
}: LinkButtonProps) {
    const classes = classNames({
        'a__button': true,
        'a__button--disabled': disabled,
    });

    return (
        <a
            className={classes}
            style={style}
            href="#"
            onClick={disabled ? (e) => e.preventDefault() : (e) => { e.preventDefault(); onClick(e); }}
        >
            {children}
        </a>
    );
}
