
import { useEffect, useRef, useState } from 'react';
import ModalDialog from '../modalDialog';
import drawMeasurementGraph from './drawMeasurementGraph';
import getMetaData, { MetaData } from './getMetaData';
import transformDataPoints from './transformDataPoints';
import { ProtocolFinal, RecordDraft, RecordWithProtocol } from '../../types/sharedTypeImpl';


interface MeasurementsGraphProps {
    className?: string,
    protocol: ProtocolFinal,
    prevRecords: RecordWithProtocol[],
    currentRecord: RecordDraft,
    selectedPositionIdx: number | undefined,
}

export default function MeasurementsGraph({
    className,
    protocol,
    prevRecords,
    currentRecord,
    selectedPositionIdx,
}: MeasurementsGraphProps) {
    const defaultContainerRef = useRef<HTMLDivElement>();
    const dialogContainerRef = useRef<HTMLDivElement>();

    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [dataPoints, setDataPoints] = useState([]);
    const [metaData, setMetaData] = useState<MetaData | null>(null);


    //Create graph data
    useEffect(() => {
        if (prevRecords == null || selectedPositionIdx == null) return undefined;
        const dataPoints = transformDataPoints(protocol, prevRecords, currentRecord, selectedPositionIdx);
        const metaData = getMetaData(dataPoints, protocol, selectedPositionIdx);
        setDataPoints(dataPoints);
        setMetaData(metaData);
    }, [protocol, prevRecords, currentRecord, selectedPositionIdx]);

    //Draw default graph
    useEffect(() => {
        if (metaData == null) return undefined;
        drawMeasurementGraph(defaultContainerRef.current, dataPoints, metaData, true);
    }, [dataPoints, metaData]);

    //Draw dialog graph
    useEffect(() => {
        if (!isDialogOpen || metaData == null) return undefined;
        drawMeasurementGraph(dialogContainerRef.current, dataPoints, metaData, false);
    }, [isDialogOpen, dataPoints, metaData]);


    return (
        <>
            <div
                ref={defaultContainerRef}
                className={className}
                onClick={() => setIsDialogOpen(true)}
            />

            <ModalDialog
                isOpen={isDialogOpen}
                cancelOnOutsideClick={true}
                onCancel={() => setIsDialogOpen(false)}
            >
                <div
                    style={{ width: '1200px', height: '400px' }}
                    ref={dialogContainerRef}
                    className={className}
                />
            </ModalDialog>
        </>
    );
}
