import { useState } from 'react';


interface AccordionProps {
    style: React.CSSProperties;
    initialOpen?: boolean;
    title: React.ReactNode;
    children: React.ReactNode;
}

export default function Accordion({
    style,
    initialOpen,
    title,
    children,
}: AccordionProps) {
    const [isActive, setIsActive] = useState(initialOpen ?? true);

    const toggleAccordion = () => {
        setIsActive(!isActive);
    };

    return (
        <div className='accordion' style={style}>
            <div className={`accordion-button ${isActive ? 'accordion-button--active' : ''}`} onClick={toggleAccordion}>
                {title}
            </div>
            <div className={`accordion-content ${isActive ? 'accordion-content--open' : ''}`}>
                {children}
            </div>
        </div>
    );
}
