import { useEffect, useState } from 'react';
import ModalSubmitDialog from '../../components/modalSubmitDialog';
import { Operator } from '../../types/sharedTypeImpl';


interface OperatorDialogProps {
    isOpen: boolean;
    operator?: Operator;
    operators: Operator[];
    onSubmit: (operator: Operator) => void;
    onClose: () => void;
}

export default function OperatorDialog({
    isOpen,
    operator,
    operators,
    onSubmit,
    onClose,
}: OperatorDialogProps) {

    const [fullName, setFullName] = useState<string>('');
    const [initials, setInitials] = useState<string>('');

    useEffect(() => {
        if (!isOpen) return;
        setFullName(operator?.fullName ?? '');
        setInitials(operator?.initials ?? '');
    }, [operator, isOpen]);

    function handleKeyDown(e: React.KeyboardEvent) {
        if (e.key === 'Enter') {
            handleSubmit();
        }
    }

    async function handleSubmit() {
        const operatorCopy = { ...operator, fullName, initials };
        if (!operatorCopy.fullName || !operatorCopy.initials) {
            alert('Please fill all fields');
            return;
        }
        if (operators.some(it => it.initials.toUpperCase() === operatorCopy.initials.toUpperCase())) {
            alert('Initials must be unique');
            return;
        }
        if (operators.some(it => it.fullName.toUpperCase() === operatorCopy.fullName.toUpperCase())) {
            alert('Name must be unique');
            return;
        }

        onSubmit(operatorCopy);
        onClose();
    }

    return (
        <ModalSubmitDialog
            frameStyle={{ width: '360px' }}
            isOpen={isOpen}
            onCancel={onClose}
            onSubmit={handleSubmit}
        >
            <div>
                <div>
                    <div>Full name</div>
                    <input
                        className='dialog-form__input'
                        type='text'
                        value={fullName}
                        onChange={e => setFullName(e.target.value)}
                        onKeyDown={handleKeyDown}
                    />
                </div>
                <div className='dialog-form__field--with-margins' >
                    <div>Initials</div>
                    <input
                        className='dialog-form__input'
                        type='text'
                        value={initials}
                        onChange={e => setInitials(e.target.value)}
                        onKeyDown={handleKeyDown}
                    />
                </div>
            </div>
        </ModalSubmitDialog>
    );
}
