import { formatDatetime } from '../../utils/fieldFormats';
import TriStateToggle from '../triStateToggle';
import { PreviewTable } from './types';
import classNames from 'classnames';


interface RecordReportTableProps {
    previewTable: PreviewTable,
    onIsIncludedChange: (recordIdx: number, isSelected: boolean) => void,
}

export function RecordReportTable({
    previewTable,
    onIsIncludedChange,
}: RecordReportTableProps) {
    return (
        <table className='record-report-table'>
            <thead>
                <tr>
                    <th>Is included</th>
                    <th>Part count</th>
                    {previewTable.positionNumbers.map((positionNumber, index) => (
                        <th key={index}>{positionNumber}</th>
                    ))}
                    <th>Gen.</th>
                    <th>Time</th>
                    <th>Comment</th>
                </tr>
            </thead>
            <tbody>
                <tr className='record-report-tr--nominal'>
                    <td />
                    <td />
                    {previewTable.nominalValues.map((nominalValue, index) => (
                        <td key={index}>{nominalValue}</td>
                    ))}
                    <td />
                    <td />
                    <td>Nominal values</td>
                </tr>

                {previewTable.recordRows.map((recordRow, index) => (
                    <tr key={index}>
                        <td>
                            <TriStateToggle
                                selection={recordRow.includeInReport}
                                onSelectionChange={(isSelected) => onIsIncludedChange(index, isSelected)}
                            />
                        </td>
                        <td className={classNames({ 'record-report-td--warning': recordRow.isPartNumberHighlighted })}>
                            {recordRow.partCount}
                        </td>
                        {recordRow.values.map((value, index) => (
                            <td
                                key={index}
                                className={classNames({ 'record-report-td--warning': value.isHighlighted })}
                            >
                                {value.value}
                            </td>
                        ))}
                        <td className={classNames({ 'record-report-td--warning': recordRow.isGeneralVisualInspectionHighlighted })}>
                            {recordRow.generalVisualInspection}
                        </td>
                        <td>{formatDatetime(recordRow.measurementStart)}</td>
                        <td className='record-report-td--comment'>{recordRow.comment}</td>
                    </tr>
                ))}
            </tbody>
        </table>
    );
}