import { MouseEvent } from 'react';


export interface TriStateToggleProps {
    style?: React.CSSProperties;
    selection: boolean | undefined;
    trueLabel?: string;
    falseLabel?: string;
    onSelectionChange: (selection: boolean) => void;
    onFocus?: () => void;
    disabled?: boolean;
}

export default function TriStateToggle({
    style,
    selection,
    trueLabel = 'Yes',
    falseLabel = 'No',
    onSelectionChange,
    onFocus,
    disabled,
}: TriStateToggleProps) {

    function handleSelectYes(e: MouseEvent) {
        e.preventDefault();
        e.stopPropagation();
        if (selection === true) return;
        onSelectionChange(true);
    }

    function handleSelectNo(e: MouseEvent) {
        e.preventDefault();
        e.stopPropagation();
        if (selection === false) return;
        onSelectionChange(false);
    }

    return (
        <span className='tristate-toggle' style={style}>
            <button
                className={`toggle-option--yes ${selection === true ? 'selected' : ''}`}
                onClick={handleSelectYes}
                onFocus={onFocus}
                disabled={disabled}
            >
                {trueLabel}
            </button>
            <button
                className={`toggle-option--no ${selection === false ? 'selected' : ''}`}
                onClick={handleSelectNo}
                onFocus={onFocus}
                disabled={disabled}
            >
                {falseLabel}
            </button>
        </span>
    );
}
