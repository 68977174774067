import { useState } from 'react';
import { formatDatetime } from '../../utils/fieldFormats';
import { fetchMaintenceOperations } from '../../api/requests';
import ModalDialog from '../../components/modalDialog';
import useFetchOnce from '../../hooks/useFetchOnce';
import AddMaintenanceOpDialog from './addMaintenanceOpDialog';
import { MaintenanceOperation } from '../../types/sharedTypeImpl';


interface MaintenanceLogDialogProps {
    isOpen: boolean;
    equipment?: string;
    onClose: () => void;
}

export default function MaintenanceLogDialog({
    isOpen,
    equipment,
    onClose,
}: MaintenanceLogDialogProps) {

    const [isAddOperationDialogOpen, setIsAddOperationDialogOpen] = useState(false);

    const { data: maintenanceOperations, isLoading, refetch } = useFetchOnce({
        queryKey: ['maintenance', equipment],
        queryFn: async () => fetchMaintenceOperations(equipment),
        enabled: isOpen,
    });


    const handleAddMaintenanceOperation = () => {
        setIsAddOperationDialogOpen(true);
    }


    if (!isOpen) return null;
    return (
        <>
            <ModalDialog
                isOpen={isOpen}
                frameClassName='modal-frame--fixed_width'
                cancelOnOutsideClick={true}
                onCancel={onClose}
            >
                {
                    isLoading
                        ? <p>Loading...</p>
                        : <MaintenanceLogTable
                            style={{ marginTop: '0.5rem', marginBottom: '2rem' }}
                            maintenanceOperations={maintenanceOperations}
                        />
                }
                <div className='dialog--button-bar'>
                    <button className='button--large' onClick={handleAddMaintenanceOperation}>Add operation</button>
                    <button className='button--large' onClick={onClose}>Close</button>
                </div>
            </ModalDialog>

            <AddMaintenanceOpDialog
                equipment={equipment}
                isOpen={isAddOperationDialogOpen}
                onOperationAdded={() => refetch()}
                onClose={() => setIsAddOperationDialogOpen(false)}
            />
        </>
    )
}

function MaintenanceLogTable({
    maintenanceOperations,
    style,
}: {
    maintenanceOperations: MaintenanceOperation[],
    style?: React.CSSProperties,
}) {
    return (
        <table style={style}>
            <thead>
                <tr>
                    <th>Comment</th>
                    <th>Date / time</th>
                    <th>Operator</th>
                </tr>
            </thead>
            <tbody>
                {
                    maintenanceOperations.map(operation => (
                        <tr key={operation._id}>
                            <td>{operation.comment}</td>
                            <td>{formatDatetime(operation.time)}</td>
                            <td>{operation.operator?.toUpperCase()}</td>
                        </tr>
                    ))
                }
            </tbody>
        </table>
    )
}
