import NavBar from '../../components/navBar';
import Diagnostics from './diagnostics';
import ReleaseNotes from './releaseNotes';


export default function Index() {
    return (
        <>
            <NavBar />
            <div className='content content--index'>
                <Diagnostics />
                <ReleaseNotes />
            </div>
        </>
    );
}
