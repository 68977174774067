import { fetchPartCountsAt } from '../../api/requests';
import useFetchOnce from '../../hooks/useFetchOnce';
import { Operation, Session } from '../../types/sharedTypeImpl';
import { formatDatetime } from '../../utils/fieldFormats';


interface BrokenToolOperationsProps {
    session: Session;
    toolOperations: Operation[];
}

export default function BrokenToolOperations({
    session,
    toolOperations
}: BrokenToolOperationsProps) {
    const operationsSorted = [...toolOperations].sort((a, b) => +b.time - +a.time);

    const operationTimes = operationsSorted.map(operation => operation.time);
    const { data: partCounts } = useFetchOnce({
        queryKey: ['/api/v1/sessions/get-part-counts-at', session.equipment, session.product, ...operationTimes],
        queryFn: async () => await fetchPartCountsAt(session, operationTimes),
    });


    const isUnscheduledToolChange = (operation: Operation) => {
        return operation.operationType === 'Tool change' && operation.isScheduled === 'Not scheduled';
    }

    const isToolChangeFix = (operation: Operation) => {
        return operation.operationType === 'Fix unscheduled tool change';
    }

    return (
        <table className='table--mid-width' style={{ marginTop: '16px' }} >
            <thead>
                <tr>
                    <th>Comment</th>
                    <th>Potential scraps</th>
                    <th>Confirmed scraps</th>
                    <th style={{ minWidth: '160px' }}>Date/time</th>
                    <th>Operator</th>
                    <th>Parts produced</th>
                    <th>Collateral tools</th>
                    <th style={{ minWidth: '120px' }} />
                </tr>
            </thead>
            <tbody>
                {operationsSorted?.map((operation, idx) => {
                    const prevPartCount = partCounts?.[idx + 1];
                    const partCount = partCounts?.[idx];
                    const partsProduced = (partCount != null ? partCount - (prevPartCount ?? 0) : null);

                    return (
                        <tr key={operation._id}>
                            <td>{operation.comment}</td>
                            <td>{operation.potentialDiscards}</td>
                            <td>{operation.discardCount}</td>
                            <td>{formatDatetime(operation.time)}</td>
                            <td>{operation.operator}</td>
                            <td>{partsProduced ?? '...'}</td>
                            <td>{operation.collateralDamage?.length ?? null}</td>
                            <td>{isUnscheduledToolChange(operation) ? 'Not scheduled'
                                : isToolChangeFix(operation) ? 'Fix'
                                    : null
                            }</td>
                        </tr>
                    );
                })}
            </tbody>
        </table >
    );
}
