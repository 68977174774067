import { PositionType } from '../../../../shared/types/protocol';
import { ContextMenu, MenuItem } from '../../components/contextMenu';
import { Point, } from '../../types';
import { Position } from '../../types/sharedTypeImpl';


interface PdfContextMenuProps {
    page: number;
    screenCoords: Point;
    pdfCoords: Point;
    pdfCoords2?: Point;
    activePosition: Position | null;
    onAddPosition?: (type: PositionType, page: number, pdfCoords: Point, pdfCoords2?: Point) => void;
    onUpdateLocation?: (page: number, pdfCoords: Point, pdfCoords2?: Point) => void;
    onAddMarker?: (pdfCoords: Point, pdfCoords2?: Point) => void;
    onClose: () => void;
}

export default function PdfContextMenu({
    page,
    screenCoords,
    pdfCoords,
    pdfCoords2,
    activePosition,
    onAddPosition,
    onUpdateLocation,
    onAddMarker,
    onClose,
}: PdfContextMenuProps) {

    const menuItems: MenuItem[] = [
        {
            text: 'Add position',
            callback: () => onAddPosition && onAddPosition('measurement', page, pdfCoords, pdfCoords2),
        },
        {
            text: 'Add visual inspection',
            callback: () => onAddPosition && onAddPosition('visualInspection', page, pdfCoords, pdfCoords2),
        },
        {
            text: 'Update current position',
            isEnabled: activePosition != null && !!onUpdateLocation,
            callback: () => onUpdateLocation && onUpdateLocation(page, pdfCoords, pdfCoords2),
        },
        {
            text: 'Add marker to position',
            isEnabled: activePosition != null && page === activePosition?.page && !!onAddMarker,
            callback: () => onAddMarker && onAddMarker(pdfCoords, pdfCoords2),
        },
    ];

    return (
        <ContextMenu
            isOpen={true}
            position={screenCoords}
            items={menuItems}
            close={onClose}
        />
    );
}
