

type QueryParam = string | number | boolean | Date | null


export function openLinkInNewTab(url: string, params?: Record<string, QueryParam>) {
    window.open(url + encodeQueryData(params), '_blank');
}


function encodeQueryData(data: Record<string, QueryParam>) {
    if (data == null) return '';

    const ret = [];
    for (const key in data) {
        const value = data[key];
        if (typeof value !== 'undefined' && value !== null) {
            ret.push(encodeQueryComponent(key) + '=' + encodeQueryComponent(value));
        }
    }
    return '?' + ret.join('&');
}

function encodeQueryComponent(value: QueryParam) {
    const valueConverted = value instanceof Date ? value.toISOString() : value;
    const encoded = encodeURIComponent(valueConverted);
    return encoded;
}
