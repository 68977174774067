import { getToleranceRange } from '../../../../shared/utils/getToleranceRange';
import { Position } from '../../types/sharedTypeImpl';
import { calcMean, calcStddev } from '../../utils/maths';


export const MIN_POINTS_FOR_SPC = 10;

export function spcChecks(
    values: (number | null)[],
    position: Position,
): FlaggedValue[] {
    if (position.type !== 'measurement') {
        throw new Error('SPC checks can only be performed on positions of type "measurement"');
    }
    const flaggedValues = createFlaggedValues(values);

    const limits = getLimits(values, position);
    if (!limits) return flaggedValues;

    checkToleranceLimits(flaggedValues, limits);
    // if (values.length >= MIN_POINTS_FOR_SPC) {
    //     checkControlLimits(flaggedValues, limits);
    //     checkOnOneSideOfCenter(flaggedValues, limits);
    //     checkBZoneLimit(flaggedValues, limits);
    //     checkConsecutiveChange(flaggedValues, limits);
    //     checkAZoneLimit(flaggedValues, limits);
    //     checkAlternatingPattern(flaggedValues);
    // }

    return flaggedValues;
}


//One point beyond the tolerance limit
function checkToleranceLimits(flaggedValues: FlaggedValue[], limits: Limits) {
    for (const flaggedValue of flaggedValues) {
        if (flaggedValue.value === null) continue;
        if (flaggedValue.value < limits.toleranceLimits[0]) flaggedValue.failedSpcCheck = true;
        if (flaggedValue.value > limits.toleranceLimits[1]) flaggedValue.failedSpcCheck = true;
    }
}

// //One point beyond the 3 σ control limit
// function checkControlLimits(flaggedValues: FlaggedValue[], limits: Limits) {
//     for (const flaggedValue of flaggedValues) {
//         if (flaggedValue.value === null) continue;
//         if (flaggedValue.value < limits.aLimits[0]) flaggedValue.failedSpcCheck = true;
//         if (flaggedValue.value > limits.aLimits[1]) flaggedValue.failedSpcCheck = true;
//     }
// }

// //Eight or more points on one side of the centerline without crossing
// function checkOnOneSideOfCenter(flaggedValues: FlaggedValue[], limits: Limits): void {
//     const NUM_CONSECUTIVE = 8;
//     let sequenceStart = 0;
//     let countOnSameSide = 0;
//     let lastDirectionAbove = null; // true if above, false if below, null if not yet determined

//     for (let i = 0; i < flaggedValues.length; i++) {
//         if (flaggedValues[i].value === null) continue;

//         const isOnCenterLine = Math.abs(flaggedValues[i].value - limits.mean) < 0.0001;
//         if (isOnCenterLine) {
//             lastDirectionAbove = null;
//             countOnSameSide = 0;
//             continue;
//         }

//         const currentDirectionAbove = flaggedValues[i].value > limits.mean;
//         if (currentDirectionAbove === lastDirectionAbove) {
//             countOnSameSide++;
//         } else {
//             countOnSameSide = 1;
//             sequenceStart = i;
//             lastDirectionAbove = currentDirectionAbove;
//         }

//         if (countOnSameSide >= NUM_CONSECUTIVE) {
//             flagValues(flaggedValues, sequenceStart, i + 1);
//             console.log('triggered: checkOnOneSideOfCenter');
//         }
//     }
// }


// //Four out of five points in zone B or beyond
// function checkBZoneLimit(flaggedValues: FlaggedValue[], limits: Limits): void {
//     const foundIssues = flagSequenceByLimits(
//         flaggedValues,
//         limits.cLimits, //zone B begins after zone C ends
//         4,
//         true
//     );
//     if (foundIssues) console.log('triggered: checkBZoneLimit');
// }

// //Six points or more in a row steadily increasing or decreasing
// function checkConsecutiveChange(flaggedValues: FlaggedValue[], limits: Limits) {
//     const NUM_CONSECUTIVE = 6;

//     let increasingCount = 1;
//     let decreasingCount = 1;

//     for (let i = 1; i < flaggedValues.length; i++) {
//         if (flaggedValues[i].value === null) continue;
//         if (flaggedValues[i].value > flaggedValues[i - 1].value) {
//             increasingCount++;
//             decreasingCount = 1;
//         } else if (flaggedValues[i].value < flaggedValues[i - 1].value) {
//             decreasingCount++;
//             increasingCount = 1;
//         } else {
//             increasingCount = 1;
//             decreasingCount = 1;
//         }

//         if (increasingCount >= NUM_CONSECUTIVE || decreasingCount >= NUM_CONSECUTIVE) {
//             // for (let j = i; j > i - NUM_CONSECUTIVE; j--) {
//             //     flaggedValues[j].failedSpcCheck = true;
//             // }
//             flagValues(flaggedValues, i - NUM_CONSECUTIVE + 1, i + 1);
//             console.log('triggered: checkConsecutiveChange');
//         }
//     }
// }


// //Two out of three points in zone A
// function checkAZoneLimit(flaggedValues: FlaggedValue[], limits: Limits): void {
//     const foundIssues = flagSequenceByLimits(
//         flaggedValues,
//         limits.bLimits, //zone A begins after zone B ends
//         2,
//         true
//     );
//     if (foundIssues) console.log('triggered: checkAZoneLimit');
// }

// //14 points in a row alternating up and down
// function checkAlternatingPattern(flaggedValues: FlaggedValue[]): void {
//     const NUM_CONSECUTIVE = 14;
//     let sequenceStart = 0;
//     let countAlternating = 1;
//     let lastDirectionUp: boolean | null = null; // true if up, false if down, null for the first comparison

//     for (let i = 1; i < flaggedValues.length; i++) {
//         if (flaggedValues[i].value === null) continue;

//         const delta = flaggedValues[i].value - flaggedValues[i - 1].value;
//         if (delta === 0) {
//             countAlternating = 0;
//             lastDirectionUp = null;
//             continue;
//         }

//         const currentChangeUp = delta > 0;
//         if (lastDirectionUp !== null && currentChangeUp !== lastDirectionUp) {
//             countAlternating++;
//         } else {
//             countAlternating = 2; // Reset to 2 to include the current and the previous point
//             sequenceStart = i - 1;
//         }

//         lastDirectionUp = currentChangeUp;

//         if (countAlternating >= NUM_CONSECUTIVE) {
//             flagValues(flaggedValues, sequenceStart, i + 1);
//             console.log('triggered: checkAlternatingPattern');
//         }
//     }
// }


// function flagSequenceByLimits(
//     flaggedValues: FlaggedValue[],
//     limits: [number, number],
//     requiredCount: number,
//     allowOneSkip: boolean
// ) {
//     let foundIssues = false;
//     for (let startIdx = 0; startIdx < flaggedValues.length - requiredCount + 1; startIdx++) {

//         let outsideCount = 0;
//         let skipUsed = false;
//         for (let currentIdx = startIdx; currentIdx < flaggedValues.length; currentIdx++) {
//             if (flaggedValues[currentIdx].value === null) continue;

//             const currentValue = flaggedValues[currentIdx].value;
//             const isOutsideLimits = currentValue > limits[1] || currentValue < limits[0];
//             if (isOutsideLimits) {
//                 outsideCount++;
//                 if (outsideCount >= requiredCount) {
//                     foundIssues = true;
//                     flagValues(flaggedValues, startIdx, currentIdx + 1);
//                 }
//             } else if (allowOneSkip && !skipUsed && currentIdx != startIdx) {
//                 skipUsed = true;
//             } else {
//                 break;
//             }

//         }
//     }
//     return foundIssues;
// }

// function flagValues(
//     flaggedValues: FlaggedValue[],
//     startIdx: number,
//     endIdxExclusive: number
// ) {
//     if (endIdxExclusive < startIdx) return;
//     for (let i = startIdx; i < endIdxExclusive; i++) {
//         flaggedValues[i].failedSpcCheck = true;
//     }
// }


function getLimits(
    values: (number | null)[],
    position: Position,
): Limits | null {
    const nonNulllValues = values.filter(it => it != null) as number[];
    if (nonNulllValues.length === 0) return null;

    const mean = calcMean(nonNulllValues);
    const stddev = calcStddev(nonNulllValues);
    const { min, max } = getToleranceRange(position);

    return {
        mean,
        toleranceLimits: [min, max],
        cLimits: [mean - stddev, mean + stddev],
        bLimits: [mean - 2 * stddev, mean + 2 * stddev],
        aLimits: [mean - 3 * stddev, mean + 3 * stddev],
    }
}

function createFlaggedValues(values: number[]): FlaggedValue[] {
    return values.map(it => ({ value: it, failedSpcCheck: false }));
}


interface FlaggedValue {
    value: number | null,
    failedSpcCheck: boolean,
}

interface Limits {
    mean: number,
    toleranceLimits: [number, number],
    cLimits: [number, number],
    bLimits: [number, number],
    aLimits: [number, number],
}
