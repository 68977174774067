import { useFetchRecordOnce } from '../../hooks/fetchHooks';
import { Record, RecordDraft, RecordFinal } from '../../types/sharedTypeImpl';
import { recordToDraft } from '../../utils/conversion';


export default function useSetupInitialRecord(
    setRecord: (record: RecordDraft) => void,
    setIsSavedRecord: (isSavedRecord: boolean) => void,
) {
    const searchParams = new URLSearchParams(window.location.search);
    const recordIdParam = searchParams.get('id');

    const recordFetch = useFetchRecordOnce(recordIdParam, (record: Record) => {
        if (record.isDraft) {
            setIsSavedRecord(false);
            setRecord(record as RecordDraft);
        } else {
            setIsSavedRecord(true);
            setRecord(recordToDraft(record as RecordFinal));
        }
    });

    return recordFetch;
}
