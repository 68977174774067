

export default function Login() {
    return (
        <div className='content'>
            <div className='login-form'>
                <h1 id='appName'>BG Web Apps</h1>
                <p>
                    <a href='/login/entra'>Sign in using Microsoft account</a>
                </p>
            </div>
        </div>
    );
}
