import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import { fetchOperators, putOperator } from '../../api/requests';
import NavBar from '../../components/navBar';
import OperatorDialog from './operatorDialog';
import { Operator } from '../../types/sharedTypeImpl';


export default function Operators() {

    const [isOperatorDialogOpen, setIsOperatorDialogOpen] = useState(false);
    const [operatorUnderEdit, setOperatorUnderEdit] = useState<Operator | undefined>(undefined);


    const { data: operators, error, isLoading, refetch } = useQuery({
        queryKey: ['operators'],
        queryFn: fetchOperators,
        select: (operators) => [...operators].sort((a, b) => a.fullName.localeCompare(b.fullName)),
    });

    const handleAddOperator = () => {
        setIsOperatorDialogOpen(true);
    }

    const handleOperatorSubmit = async (operator: Operator) => {
        await putOperator({ ...operator, isActive: true });
        refetch();
    }

    const handleIsActiveChange = async (operator: Operator, isActive: boolean) => {
        await putOperator({ ...operator, isActive });
        refetch();
    }


    return (
        <>
            <NavBar />
            <div className='content'>
                {
                    error ? <div>An error has occurred: {error.message}</div> :
                        isLoading ? <div>Loading...</div> :
                            <>
                                <button onClick={handleAddOperator}>Add operator</button>
                                <OperatorsTable
                                    style={{ width: '800px' }}
                                    operators={operators}
                                    onIsActiveChange={handleIsActiveChange}
                                />
                            </>
                }
                <OperatorDialog
                    isOpen={isOperatorDialogOpen}
                    operator={operatorUnderEdit}
                    operators={operators}
                    onSubmit={(operator: Operator) => handleOperatorSubmit(operator)}
                    onClose={() => { setIsOperatorDialogOpen(false); setOperatorUnderEdit(undefined); }}
                />
            </div >
        </>
    );
}

function OperatorsTable({
    style,
    operators,
    onIsActiveChange,
}: {
    style: React.CSSProperties;
    operators: Operator[];
    onIsActiveChange: (operator: Operator, isActive: boolean) => void;
}) {
    return (
        <table style={style}>
            <thead>
                <tr>
                    <th>Is active</th>
                    <th>Full name</th>
                    <th>Initials</th>
                </tr>
            </thead>
            <tbody>
                {operators?.map(it =>
                    <tr key={it._id}>
                        <td>
                            <input
                                type="checkbox"
                                checked={it.isActive}
                                onChange={(e) => onIsActiveChange(it, e.target.checked)}
                            />
                        </td>
                        <td>{it.fullName}</td>
                        <td>{it.initials}</td>
                    </tr>
                )}
            </tbody>
        </table>
    );
}
