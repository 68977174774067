import { useState } from 'react';
import ModalSubmitDialog from '../../components/modalSubmitDialog';


interface RestartFeedbackDialogProps {
    isOpen: boolean;
    onCancel: () => void;
    onSubmit: (feedback: string) => void;
}

export default function RestartFeedbackDialog({
    isOpen,
    onCancel,
    onSubmit,
}: RestartFeedbackDialogProps) {
    const [feedback, setFeedback] = useState('');

    function handleSubmit() {
        onSubmit(feedback);
    }

    return (
        <ModalSubmitDialog isOpen={isOpen} onCancel={onCancel} onSubmit={handleSubmit}>
            <p>Describe why a restart is needed</p>
            <textarea
                style={{ width: '600px' }}
                rows={3}
                value={feedback}
                onChange={e => setFeedback(e.target.value)}
            />
        </ModalSubmitDialog>
    );
}
