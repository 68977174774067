
import { getToleranceRange } from '../../../shared/utils/getToleranceRange';
import { Position, ProtocolFinal, RecordDraft } from '../types/sharedTypeImpl';


/**
 * Determines if the given position is outside the tolerance range.
 * Returns false if the value is null or an empty string.
 * Returns true if the value is outside the tolerance range.
 * Also returns `true` if the value is a non-empty string, but cannot be parsed as a number.
 * 
 * @param position - The position to check.
 * @param value - The value to compare against the position's tolerance range.
 * @returns `true` if the value is outside the tolerance range, `false` otherwise.
 * @throws {Error} If the position is not a measurement.
 */
export function isOutsideTolerance(position: Position, value: string | number | boolean | null | undefined): boolean {
    const isEmpty = value == null || value === '';
    if (isEmpty) return false;

    if (position.type === 'measurement') {
        const valueAsNumber = typeof value === 'string' ? parseFloat(value) : value as number;
        const { min, max } = getToleranceRange(position);
        const isOutOfSpec = isNaN(valueAsNumber) || valueAsNumber < min || valueAsNumber > max;
        return isOutOfSpec;
    } else {
        return !(value as boolean);
    }
}


export function getNumOutOfTolerance(record: RecordDraft, protocol: ProtocolFinal) {
    let numOutOfTolerance = 0;
    for (let i = 0; i < protocol.positions.length; i++) {
        const position = protocol.positions[i];
        const value = record.positionValues[i]!.value as string | number;
        if (isOutsideTolerance(position, value)) {
            numOutOfTolerance++;
        }
    }

    const generalInspectionFailed = record.generalVisualInspections === false;

    return numOutOfTolerance + (generalInspectionFailed ? 1 : 0);
}
