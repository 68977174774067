import { PositionBase } from '../types/protocol.js';


export function getToleranceRange(position: PositionBase): { min: number, max: number } {
    const precision = getPrecision(position);
    const min = roundToPrecision(+position.nominal! + +position.lowerTolerance!, precision);
    const max = roundToPrecision(+position.nominal! + +position.upperTolerance!, precision);
    return { min, max };
}

export function getToleranceRangeStrings(position: PositionBase): { min: string, max: string } {
    const precision = getPrecision(position);
    const { min, max } = getToleranceRange(position);
    return {
        min: min.toFixed(precision),
        max: max.toFixed(precision),
    }
}

function getPrecision(position: PositionBase): number {
    const nominalPrecision = position.nominal!.toString().split('.')[1]?.length;
    const upperTolerancePrecision = position.upperTolerance!.toString().split('.')[1]?.length;
    const lowerTolerancePrecision = position.lowerTolerance!.toString().split('.')[1]?.length;
    const precision = Math.max(nominalPrecision, upperTolerancePrecision, lowerTolerancePrecision, 2);
    return precision;
}

function roundToPrecision(value: number, precision: number) {
    const factor = 10 ** precision;
    return Math.round(value * factor) / factor;
}
