import { DisplayOptions } from '../../components/equipment-oee-graph/displayOptions';


interface DisplayControlProps {
    style: React.CSSProperties;
    displayOptions: DisplayOptions;
    setDisplayOptions: (displayOptions: DisplayOptions) => void;
}

export default function DisplayControls({
    style,
    displayOptions,
    setDisplayOptions,
}: DisplayControlProps) {

    const handleShiftSelectionChange = (shift: string, isEnabled: boolean) => {
        const visibleShifts = isEnabled
            ? [...displayOptions.visibleShifts, shift]
            : displayOptions.visibleShifts.filter(s => s !== shift);

        setDisplayOptions({ ...displayOptions, visibleShifts });
    }


    return (
        <div
            className='flex--vert'
            style={{
                ...style,
                gap: '12px',
            }}
        >
            <div className='flex--horz' style={{ gap: '20px' }}>
                <label>
                    <input
                        style={{ marginRight: '8px' }}
                        type='checkbox'
                        checked={displayOptions.visibleShifts.includes('morning')}
                        onChange={e => handleShiftSelectionChange('morning', e.target.checked)}
                    />
                    Morning
                </label>
                <label>
                    <input
                        style={{ marginRight: '8px' }}
                        type='checkbox'
                        checked={displayOptions.visibleShifts.includes('evening')}
                        onChange={e => handleShiftSelectionChange('evening', e.target.checked)}
                    />
                    Evening
                </label>
                <label>
                    <input
                        style={{ marginRight: '8px' }}
                        type='checkbox'
                        checked={displayOptions.visibleShifts.includes('night')}
                        onChange={e => handleShiftSelectionChange('night', e.target.checked)}
                    />
                    Night
                </label>
            </div>

            <div className='flex--horz' style={{ gap: '12px' }}>
                <label>
                    <input
                        style={{ marginRight: '8px' }}
                        type='checkbox'
                        checked={displayOptions.excludeWeekends}
                        onChange={e => setDisplayOptions({ ...displayOptions, excludeWeekends: e.target.checked })}
                    />
                    Exclude weekends
                </label>
            </div>

            <div className='flex--horz' style={{ gap: '12px' }}>
                <label htmlFor='slider'>Time frame length</label>
                <input
                    id='slider'
                    type='range'
                    min={1}
                    max={7}
                    step={1}
                    value={displayOptions.timeFrameDays}
                    onChange={(e) => setDisplayOptions({ ...displayOptions, timeFrameDays: +e.target.value })}
                />
                <span>{displayOptions.timeFrameDays} days</span>
            </div>

            <div className='flex--horz' style={{ gap: '12px' }}>
                <label htmlFor='smoothing'>Smoothing amount</label>
                <input
                    id='smoothing'
                    type='range'
                    min={0}
                    max={2}
                    step={1}
                    value={displayOptions.smoothingAmount}
                    onChange={(e) => setDisplayOptions({ ...displayOptions, smoothingAmount: +e.target.value })}
                />
                <span>{displayOptions.smoothingAmount} bars</span>
            </div>

            <div className='flex--horz' style={{ gap: '12px' }}>
                <label htmlFor='resolution'>Resolution</label>
                <select
                    id='resolution'
                    value={displayOptions.resolution}
                    onChange={(e) => setDisplayOptions({ ...displayOptions, resolution: e.target.value as any })}
                >
                    <option value='halfHour'>Half hour</option>
                    <option value='hour'>Hour</option>
                </select>
            </div>

            <div className='flex--horz' style={{ gap: '12px' }}>
                <label htmlFor='valueType'>Value type</label>
                <select
                    id='valueType'
                    value={displayOptions.valueType}
                    onChange={(e) => setDisplayOptions({ ...displayOptions, valueType: e.target.value as any })}
                >
                    <option value='availability'>Availability</option>
                    <option value='quality'>Quality</option>
                    <option value='scraps'>Scraps</option>
                    <option value='performance'>Performance</option>
                    <option value='utilization'>Utilization</option>
                    <option value='oee'>OEE</option>
                    <option value='oeeWithoutSetup'>OEE without setup</option>
                    <option value='teep'>TEEP</option>
                </select>
            </div>
        </div>
    )
}
