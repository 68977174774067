import { NewOperation, Operation } from '../../types/sharedTypeImpl';


export interface OperationDraft {
    //Individual fields for the details array
    operationType?: string,
    machineComponent?: string,
    machineMainComponent?: string,
    plusOrMinus?: string,
    adjustmentText?: string,
    toolVariant?: string,
    toolNumber?: string,
    dimension?: string,
    offset?: string,
    isScheduled?: string,
    toolFailReason?: string,
    toolBreakReason?: string,
    programEditText?: string,
    collateralDamage?: Tool[],
    fixedTools?: Tool[],
    details: string[],

    //Common fields
    discardCount?: string,
    potentialDiscards?: string,
    orderNumber?: string,
    comment?: string,
    operator?: string,
}

export interface Tool {
    toolVariant: string,
    toolNumber: string,
}


export type OperationFormResult = Omit<NewOperation, 'equipment' | 'product'>;


export function operationToDraft(operation: Operation): OperationDraft {
    return {
        operationType: operation.operationType,
        machineComponent: operation.machineComponent,
        machineMainComponent: operation.machineMainComponent,
        plusOrMinus: operation.plusOrMinus,
        adjustmentText: operation.adjustmentText,
        toolVariant: operation.toolVariant,
        toolNumber: operation.toolNumber,
        dimension: operation.dimension,
        offset: operation.offset?.toString(),
        isScheduled: operation.isScheduled,
        toolFailReason: operation.toolFailReason,
        toolBreakReason: operation.toolBreakReason,
        programEditText: operation.programEditText,
        collateralDamage: operation.collateralDamage,
        fixedTools: operation.fixedTools,
        details: operation.details,
        discardCount: operation.discardCount?.toString() ?? '',
        potentialDiscards: operation.potentialDiscards?.toString() ?? '',
        comment: operation.comment,
        operator: operation.operator,
        orderNumber: operation.orderNumber,
    }
}

export function draftToOperationFormValues(draft: OperationDraft): OperationFormResult {
    return {
        ...draft,
        operationType: draft.operationType!,
        discardCount: draft.discardCount ? parseInt(draft.discardCount) : null,
        potentialDiscards: draft.potentialDiscards ? parseInt(draft.potentialDiscards) : null,
        offset: draft.offset ? parseFloat(draft.offset) : null,
        comment: draft.comment!,
        operator: draft.operator!,
    }
}
