import { useEffect, useRef } from 'react';
import { Point, Rect, Size } from '../../types';
import { defaultZoomPanValues, getOffsetOnPan, getOffsetOnZoom } from './zoomPanUtils';


interface UseZoomPanProps {
    containerRef: React.RefObject<HTMLDivElement>;
    contentRef: React.RefObject<HTMLCanvasElement>;
    // pdfSize: React.RefObject<Size | null>;
    pdfSize: Size | null;
    scale: number | null;
    setScale: (scale: number) => void;
    offset: React.MutableRefObject<Point>;
    onZoomPan: () => void;
}

export default function useZoomPan({
    containerRef,
    contentRef,
    pdfSize,
    scale,
    setScale,
    offset,
    onZoomPan,
}: UseZoomPanProps) {
    const zoomDelta: number = 0.5;

    const isPanning = useRef(false);
    const panStartOffset = useRef<Point | null>(null);
    const panStartMousePos = useRef<Point | null>(null);


    const fitToHeight = () => {
        if (!scale || !pdfSize) return;

        const containerSize: Size = { width: containerRef.current.clientWidth, height: containerRef.current.clientHeight };
        const [newOffset, newScale] = defaultZoomPanValues(containerSize, pdfSize);
        offset.current = newOffset;

        if (newScale != scale) {
            setScale(newScale);
        } else {
            onZoomPan();
        }
    }

    const onMouseWheel = (e: React.WheelEvent) => {
        if (!scale || !pdfSize) return;

        const zoomIn = e.deltaY < 0;
        const mousePos = { x: e.clientX, y: e.clientY };

        const newScale = zoomIn
            ? scale + zoomDelta
            : Math.max(0.5, scale - zoomDelta);
        const containerSize: Size = { width: containerRef.current.clientWidth, height: containerRef.current.clientHeight };
        const pdfViewSize: Size = { width: contentRef.current.clientWidth, height: contentRef.current.clientHeight };
        const containerClientRect = containerRef.current.getBoundingClientRect() as Rect;
        const pdfViewClientRect = contentRef.current.getBoundingClientRect() as Rect;

        //Wheel events may be triggered faster than the browser can render the view.
        //Only update the view if the scale and offset are close to what we expect.
        const realScale = pdfViewClientRect.width / pdfSize.width;
        const realOffset = { x: pdfViewClientRect.left, y: pdfViewClientRect.top };
        if (Math.abs(scale - realScale) > 0.005) return;
        if (Math.abs(offset.current.x - realOffset.x) > 0.5) return;

        offset.current = getOffsetOnZoom(
            scale,
            newScale,
            offset.current,
            mousePos,
            containerSize,
            pdfViewSize,
            containerClientRect
        );
        setScale(newScale);
        // onZoomPan(); //Setting scale already updates view. Having this might cause flickering
    };

    const onMouseDown = (e: React.MouseEvent) => {
        if (!scale || !pdfSize) return;
        if (e.button !== 0) return; // Only continue if the button is mouse1
        isPanning.current = true;
        panStartOffset.current = { ...offset.current };
        panStartMousePos.current = { x: e.clientX, y: e.clientY };
    }

    const onMouseMove = (e: React.MouseEvent) => {
        if (!scale || !pdfSize) return;
        if (!isPanning.current) return;

        const currentMousePos: Point = { x: e.clientX, y: e.clientY };
        const containerSize: Size = { width: containerRef.current.clientWidth, height: containerRef.current.clientHeight };
        const pdfViewSize: Size = { width: contentRef.current.clientWidth, height: contentRef.current.clientHeight };

        offset.current = getOffsetOnPan(
            currentMousePos,
            panStartMousePos.current,
            panStartOffset.current,
            containerSize,
            pdfViewSize,
        );
        onZoomPan();
    }

    //On mouse up, even if outside the container
    useEffect(() => {
        const onMouseUp = (e: MouseEvent) => {
            if (e.button !== 0) return; // Only continue if the button is mouse1
            isPanning.current = false;
            panStartOffset.current = null;
            panStartMousePos.current = null;
        }

        window.addEventListener('mouseup', onMouseUp);
        return () => {
            window.removeEventListener('mouseup', onMouseUp);
        };
    }, []);


    return {
        fitToHeight,
        onMouseWheel,
        onMouseDown,
        onMouseMove,
    }
}
