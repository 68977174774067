import { Link } from 'react-router-dom';
import { formatCycleTime, formatDatetime, formatKpiAllAndRecent } from '../../utils/fieldFormats';
import LinkButton from '../linkButton';
import OperationDetails from './operationDetails';
import classNames from 'classnames';
import { Operation, OperationWithAlarm } from '../../types/sharedTypeImpl';
import { CycleTimeInfo } from '../../../../shared/types/operation';


interface OperationsTableProps {
    narrow?: boolean;
    operations: (Operation | OperationWithAlarm)[];
    showSession: boolean;
    operationTypeIndicators: boolean;
    highlightPotentialDiscards: boolean;
    showScrapLabels: boolean;
    showOrderNumbers: boolean;
    isEditableIfScrapClosed: boolean;
    enableScrapClose: boolean;
    showScrapCloseValues?: boolean;
    onEditClick: (operation: Operation) => void;
    onScrapCloseClick?: (operation: Operation) => void;
}

export default function OperationsTable({
    narrow,
    operations,
    showSession,
    operationTypeIndicators,
    highlightPotentialDiscards,
    isEditableIfScrapClosed,
    showScrapLabels,
    showOrderNumbers,
    enableScrapClose,
    showScrapCloseValues,
    onEditClick,
    onScrapCloseClick,
}: OperationsTableProps) {

    function getOperationTypeClass(operation: Operation): string {
        let backgroundClass = '';
        if (operation.details[0] === 'Adjustments') {
            backgroundClass = 'td__background--adjustment';
        } else if (operation.details[0] === 'Offset') {
            backgroundClass = 'td__background--offset';
        } else if (operation.details[0] === 'Tool change') {
            backgroundClass = 'td__background--tool-change';
        } else if (operation.details[0] === 'Program edit') {
            backgroundClass = 'td__background--program-edit';
        } else if (operation.details[0] === 'Fix unscheduled tool change') {
            backgroundClass = 'td__background--fix-tool-change';
        } else if (operation.details[0] === 'Measurement') {
            backgroundClass = 'td__background--measurement';
        } else {
            throw new Error('Invalid operation type: ' + operation.details[0]);
        }
        return backgroundClass;
    }

    function getCycleTimeIf(kpis: CycleTimeInfo) {
        return kpis?.numParts >= 5 ? formatCycleTime(kpis.cycleTimeMedian) : null;
    }

    return (
        <table className='operations-table'>
            <thead>
                <tr>
                    {operationTypeIndicators && <th style={{ width: '20px', minWidth: '20px' }} />}
                    {showSession && <th style={{ width: '140px' }}>Equipment</th>}
                    {showSession && <th style={{ width: '175px' }}>Part name</th>}
                    <th style={{ width: '420px' }}>Operation performed</th>
                    <th style={{ width: narrow ? '200px' : '300px' }}>Comment</th>
                    {showScrapLabels && <th style={{ width: '55px' }}>Label</th>}
                    {showOrderNumbers && <th style={{ width: '90px' }}>Order number</th>}
                    <th style={{ width: narrow ? '75px' : '80px' }}>Potential scrap</th>
                    <th style={{ width: narrow ? '85px' : '90px' }}>Confirmed scrap</th>
                    <th style={{ width: narrow ? '60px' : '70px' }}>Part count</th>
                    <th style={{ width: narrow ? '120px' : '130px' }} title='Median cycle time after setup&#10;Latest 8 hours shown in parentheses'>Cycle time</th>
                    <th style={{ width: narrow ? '130px' : '150px' }}>Date/time</th>
                    <th style={{ width: '80px' }}>Operator</th>
                    <th style={{ width: narrow ? '40px' : '50px' }} />
                    {enableScrapClose && <th style={{ width: '50px' }} />}
                </tr>
            </thead>

            <tbody>
                {operations.map((operation) => {
                    const hasPendingPotentialScrap = highlightPotentialDiscards && operation.potentialDiscards > 0 && !operation.scrapCloseForm;
                    const rowClass = classNames({ 'highlight--issue': hasPendingPotentialScrap });

                    const isPotentialScrapHandled = operation.scrapCloseForm != null;
                    const potentialScrapClass = classNames({ 'handled-potential-scrap': isPotentialScrapHandled });

                    const confirmedScrapFinal = operation.scrapCloseForm?.finalConfirmedScrap ?? operation.discardCount;

                    const cycleTimes = formatKpiAllAndRecent(
                        getCycleTimeIf(operation.cycleTimeOverall),
                        getCycleTimeIf(operation.cycleTimeRecent),
                    );

                    const isEditable = operation.operationType !== 'Measurement'
                        && (isEditableIfScrapClosed || operation.scrapCloseForm == null);

                    return (
                        <tr key={operation._id} className={rowClass}>
                            {operationTypeIndicators && <td className={getOperationTypeClass(operation)} />}
                            {showSession && <td>
                                <Link className='link-button--non-intrusive' to={`/machine-log#equipment=${operation.equipment}`}>
                                    {operation.equipmentDisplayName}
                                </Link>
                            </td>}
                            {showSession && <td title={operation.product}>
                                {operation.partDisplayName}
                            </td>}
                            <td><OperationDetails operation={operation} /></td>
                            <td>
                                <div>{operation.comment}</div>
                                {(operation.scrapCloseForm?.comment && showScrapCloseValues) &&
                                    <div style={{ borderTop: '1px solid #bbb' }}>{operation.scrapCloseForm?.comment}</div>
                                }
                            </td>
                            {showScrapLabels && <td>{operation.scrapLabel}</td>}
                            {showOrderNumbers && <td>{operation.orderNumber}</td>}
                            <td className={potentialScrapClass}>{operation.potentialDiscards}</td>
                            <td>{confirmedScrapFinal}</td>
                            <td>{operation.partCount}</td>
                            <td>{cycleTimes}</td>
                            <td>
                                <div>{formatDatetime(operation.time)}</div>
                                {(operation.scrapCloseForm?.time && showScrapCloseValues) &&
                                    <div style={{ borderTop: '1px solid #bbb' }}>{formatDatetime(operation.scrapCloseForm?.time)}</div>
                                }
                            </td>
                            <td>
                                <div>{operation.operator?.toUpperCase()}</div>
                                {(operation.scrapCloseForm?.operator && showScrapCloseValues) &&
                                    <div style={{ borderTop: '1px solid #bbb' }}>{operation.scrapCloseForm?.operator?.toUpperCase()}</div>
                                }
                            </td>
                            <td>{isEditable && <LinkButton onClick={() => onEditClick(operation)}>Edit</LinkButton>}</td>
                            {enableScrapClose && <td><LinkButton onClick={() => onScrapCloseClick(operation)}>Close</LinkButton></td>}
                        </tr>
                    )
                })}
            </tbody>
        </table>
    )
}
