

export function getSessionTimeRemainingStyle(
    sessionTimeRemaining: number,
    partCount: number,
    targetQuantity: number,
    inOfflineMode: boolean
): React.CSSProperties {
    const warningPeriodMillis = 8 * 3_600_000;
    const considerCount = targetQuantity > 0 && !inOfflineMode;
    const countReached = partCount >= targetQuantity;
    const timerReached = sessionTimeRemaining <= 0;
    const requiresMeasuring = considerCount ? countReached : timerReached;

    let cellStyle: React.CSSProperties = {};
    if (requiresMeasuring) {
        cellStyle = { color: 'red' };
    } else if (sessionTimeRemaining <= warningPeriodMillis) {
        cellStyle = { color: 'orange' };
    }
    return cellStyle;
}
