import { useEffect } from 'react';


interface UseMouseEventsProps {
    onMouseDown?: (event: MouseEvent) => void;
    onMouseMove?: (event: MouseEvent) => void;
    onMouseUp?: (event: MouseEvent) => void;
}

export default function useMouseEvents({
    onMouseDown,
    onMouseMove,
    onMouseUp,
}: UseMouseEventsProps) {

    useEffect(() => {
        const handleMouseDown = (event: MouseEvent) => {
            onMouseDown(event);
        };

        const handleMouseMove = (event: MouseEvent) => {
            onMouseMove(event);
        };

        const handleMouseUp = (event: MouseEvent) => {
            onMouseUp(event);
        };

        window.addEventListener('mousedown', handleMouseDown);
        window.addEventListener('mousemove', handleMouseMove);
        window.addEventListener('mouseup', handleMouseUp);

        return () => {
            window.removeEventListener('mousedown', handleMouseDown);
            window.removeEventListener('mousemove', handleMouseMove);
            window.removeEventListener('mouseup', handleMouseUp);
        };
    }, [onMouseDown, onMouseMove, onMouseUp]);
}
