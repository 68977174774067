import ModalDialog from '../modalDialog';
import ProgramNameMappings from './programNameMappings';


interface ProgramNameMappingDialogProps {
    onClose: () => void;
}

export default function ProgramNameMappingDialog({
    onClose,
}: ProgramNameMappingDialogProps) {

    return (
        <ModalDialog
            isOpen={true}
            onCancel={onClose}
        >
            <ProgramNameMappings />
        </ModalDialog>
    );
}
