import { Tool } from '../components/operation-dialog/operationDraft';
import { Operation, OperationWithAlarm } from '../types/sharedTypeImpl';


/* Adds flags to the operations to indicate active tool change alarms and fixed alarms.
Returns the list of operations sorted by time in ascending order. */
export function getOperationsWithAlarms(operations: Operation[]): OperationWithAlarm[] {
    return findAlarms(operations).flat;
}

export function countActiveToolChangeAlarms(operations: Operation[]): number {
    return findAlarms(operations).flat.filter(it => it.toolChangeAlarm === 'active').length;
}

export function getToolsWithActiveAlarms(operations: Operation[]): Tool[] {
    const { grouped } = findAlarms(operations);
    const tools = [...grouped]
        .filter(([_key, operations]) => operations.some(it => it.toolChangeAlarm === 'active'))
        .map(([tool, _operations]) => getToolFromKey(tool));
    return tools;
}

export function countUnfixedUnscheduledToolChangesByTool(operations: Operation[]): {
    toolVariant: string,
    toolNumber: string,
    count: number,
    relatedOperations: OperationWithAlarm[],
}[] {
    const counts = [];
    const { grouped } = findAlarms(operations);
    for (const [key, group] of grouped) {
        let count = 0;
        for (let i = group.length - 1; i >= 0; i--) {
            const operation = group[i];
            if (operation.operationType === 'Tool change' && operation.isScheduled === 'Not scheduled') {
                count++;
            } else if (operation.operationType.startsWith('Fix')) {
                break;
            }
        }

        counts.push({
            ...getToolFromKey(key),
            count,
            relatedOperations: group,
        });
    }
    return counts;
}


function findAlarms(operations: Operation[]): {
    flat: OperationWithAlarm[],
    grouped: Map<string, OperationWithAlarm[]>,
} {
    const operationsWithAlarms = operations
        .map(it => ({ ...it }))
        .sort((a, b) => +a.time - +b.time) as OperationWithAlarm[];

    //Group unscheduled tool change operations and fix operations by tools. Keep the order of the operations.
    const groupedByTool = new Map<string, OperationWithAlarm[]>();
    for (const operation of operationsWithAlarms) {
        if (operation.operationType === 'Tool change') {
            const key = getKey(operation);
            groupedByTool.set(key, groupedByTool.get(key)?.concat(operation) ?? [operation]);
        } else if (operation.operationType.startsWith('Fix')) {
            for (const fixedTool of operation.fixedTools ?? []) {
                const key = getKey(fixedTool);
                groupedByTool.set(key, groupedByTool.get(key)?.concat(operation) ?? [operation]);
            }
        }
    }

    //Flag repeated tool changes as active alarms
    for (const group of groupedByTool.values()) {
        let prevUnfixed: OperationWithAlarm | undefined = undefined;
        for (const operation of group) {
            const currIsUnscheduledChange = operation.operationType === 'Tool change' && operation.isScheduled === 'Not scheduled';
            if (prevUnfixed && currIsUnscheduledChange) {
                prevUnfixed.toolChangeAlarm = 'active';
                operation.toolChangeAlarm = 'active';
            }
            if (currIsUnscheduledChange) {
                prevUnfixed = operation;
            } else if (operation.operationType.startsWith('Fix')) {
                prevUnfixed = undefined;
            }
        }
    }

    //Change active alarm to fixed if a newer fix operation is found
    for (const group of groupedByTool.values()) {
        let fixOperationFound = false;
        for (let i = group.length - 1; i >= 0; i--) {
            const operation = group[i];
            if (operation.operationType.startsWith('Fix')) {
                fixOperationFound = true;
            } else if (fixOperationFound && operation.toolChangeAlarm === 'active') {
                operation.toolChangeAlarm = 'fixed';
            }
        }
    }

    return {
        flat: operationsWithAlarms,
        grouped: groupedByTool,
    };
}


function getKey(operationOrTool: Operation | Tool): string {
    return operationOrTool.toolVariant + '_' + operationOrTool.toolNumber;
}

function getToolFromKey(key: string): Tool {
    const components = key.split('_') as [string, string];
    return {
        toolVariant: components[0],
        toolNumber: components[1],
    };
}
