import { Tool } from '../components/operation-dialog/operationDraft';


// const NBSP_D = '\u00A0';//Default non-breaking space
const NBSP_N = '\u202F';//Narrow non-breaking space


export function fieldIsLoadingText(): string {
    return '...';
}

export function formatProduct(product: string | undefined): string {
    if (!product) return '';
    return product;
}

export function formatSessionStart(sessionStart: Date): string {
    return formatDatetime(sessionStart);
}

export function formatSessionEnd(sessionEnd: Date): string {
    return formatDatetime(sessionEnd);
}

export function formatSetupTime(setupTime: number | undefined): string {
    if (!setupTime) return '';
    return formatDuration(setupTime, true, false);
}

export function formatLatestData(latestData: Date): string {
    return formatTime(latestData);
}

export function formatKpiAllAndRecent(overall: string | undefined, recent: string | undefined): string {
    if (!overall) return '';
    return overall + (recent ? ` (${recent})` : '');
}

export function formatCycleTime(cycleTime: number | undefined): string {
    if (!cycleTime) return '';
    return formatDuration(cycleTime, false, true);
}

export function formatPartsPerHour(partsPerHour: number | undefined): string {
    if (partsPerHour == null) return '';

    const partsPerHourRounded = Math.round(partsPerHour * 10) / 10.0;
    return `${partsPerHourRounded}`;
}

export function formatQuality(quality: number | undefined): string {
    if (quality == null) return '';
    return `${(quality * 100).toFixed(0)}%`;
}

export function formatPartsProduced(partsProduced: number | undefined): string {
    if (partsProduced == null) return '';

    const partsProducedRounded = Math.round(partsProduced);
    return `${partsProducedRounded}`;
}

export function formatEfficiency(efficiency: number | undefined): string {
    if (efficiency == null) return '';
    return `${Math.round(efficiency * 100)}%`;
}

export function formatPricePerItem(pricePerItem: number | undefined): string {
    if (!pricePerItem) return '';
    return pricePerItem + '';
}

export function formatEurosPerHour(eurosPerHour: number | undefined): string {
    if (!eurosPerHour) return '';
    return eurosPerHour.toFixed(2);
}

export function formatOperationDetails(
    operationDetails: string[] | null,
    toolChangeAlarm: 'active' | 'fixed' | undefined,
    collateralDamage: Tool[] | undefined
): string {
    if (operationDetails == null) return '';

    const toolChangeIcon =
        toolChangeAlarm === 'active' ? ' &#128308;' : toolChangeAlarm === 'fixed' ? ' &#x26AA;' : '';
    const mainText = operationDetails.join(' > ') + toolChangeIcon;

    const collateralList = collateralDamage?.map((it) => it.toolVariant + ' ' + it.toolNumber);
    const collateralText = collateralList?.length > 0 ? `(Collateral: ${collateralList.join('; ')})` : null;

    return [mainText, collateralText].join('<br>');
}

export function formatBrokenToolFrequency(frequency: number | undefined): string {
    if (!frequency) return '';

    return frequency <= 1.0 ? frequency.toFixed(1) : frequency.toFixed(0);
}

export function formatDatetime(datetime: Date | undefined, options?: { nonBreakingSpace: boolean }): string {
    if (!datetime) return '';
    let formatted = datetime.toLocaleTimeString('et', {
        year: 'numeric',
        month: '2-digit',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
    });
    if (options && options.nonBreakingSpace) {
        formatted = formatted.replace(/\s/g, NBSP_N);
    }
    return formatted;
}

export function formatTime(datetime: Date | undefined): string {
    if (!datetime) return '';
    return datetime.toLocaleTimeString('et', {
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
    });
}

export function formatBatchTimeRemaining(millisRemaining: number): string {
    const hoursRemaining = millisRemaining / 3600_000;
    const hoursRounded = hoursRemaining >= 0 ? Math.floor(hoursRemaining) : Math.ceil(hoursRemaining);
    if (hoursRounded !== 0) return `${hoursRounded}h`;

    const minutesRemaining = millisRemaining / 60_000;
    const minutesRounded = minutesRemaining >= 0 ? Math.floor(minutesRemaining) : Math.ceil(minutesRemaining);
    return `${minutesRounded}m`;
}

export function formatDuration(timeMillis: number | null, alwaysShowHours: boolean, showSeconds: boolean): string {
    if (timeMillis == null) return '';

    const totalSeconds = Math.round(Math.abs(timeMillis / 1000));

    const hours = Math.floor(totalSeconds / 3600);
    const hoursText = (hours || alwaysShowHours) ? `${hours.toString()}h` : '';

    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const minutesPadding = (hoursText && minutes < 10 ? '0' : '');
    const minutesText = minutesPadding + minutes.toString() + 'm';

    const seconds = totalSeconds % 60;
    let secondsText;
    if (showSeconds) {
        const secondsPadding = (seconds < 10 ? '0' : '');
        secondsText = secondsPadding + seconds.toString() + 's';
    } else {
        secondsText = '';
    }

    const minus = timeMillis < 0 ? '-' : '';
    const out = minus + [hoursText, minutesText, secondsText].filter((it) => it).join(NBSP_N);
    return out;
}
