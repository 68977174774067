import { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { PositionType } from '../../../../shared/types/protocol';
import { useUpdateProtocol } from '../../components/protocol-form/useUpdateProtocol';
import ProtocolPdf from '../../components/protocolPdf/protocolPdf';
import VisualInspectionImage from '../../components/visualInspectionImage';
import useConfirmOnLeave from '../../hooks/useConfirmOnLeave';
import { Point } from '../../types';
import { ProtocolDraft } from '../../types/sharedTypeImpl';
import useProtocolSave from './useProtocolSave';
import useProtocolInit from './useProtocolnit';
import ProtocolForm from '../../components/protocol-form/protocolForm';
import PositionSelection from '../../types/positionSelection';


export default function ProtocolView() {

    const [protocol, setProtocol] = useState<ProtocolDraft | null>(null);
    const [pdfData, setPdfData] = useState<Uint8Array | null>(null);
    const [positionSelection, setPositionSelection] = useState<PositionSelection | null>(null);
    const [showAllPositions, setShowAllPositions] = useState(false);
    const [isDirty, setIsDirty] = useState(false);
    const isFinalSavedRef = useRef(false);


    const {
        isProtocolLoading,
        isPdfLoading,
        protocolError,
        mode,
        isViewMode,
        savedPositionImages,
    } = useProtocolInit(setProtocol, setPdfData);

    const {
        addPosition,
        addMarker,
        setPositionImage,
        setMarkerLocation,
    } = useUpdateProtocol(protocol, setProtocol, setPositionSelection, setIsDirty);

    const {
        isSaving,
        handleDraftSave,
        handleFinalSave,
        handleReviewSave,
    } = useProtocolSave({
        protocol,
        setProtocol,
        pdfData,
        isDirty,
        setIsDirty,
        isViewMode,
        isFinalSavedRef,
        onFinalSaved: () => handleClose(),
        onReviewSaved: () => handleClose(),
    });

    useConfirmOnLeave(() => isDirty && !isFinalSavedRef.current, !isViewMode);

    const navigate = useNavigate();


    function handleAddPosition(type: PositionType, page: number, pdfCoords: Point, pdfCoords2?: Point) {
        addPosition(type, page, pdfCoords, pdfCoords2);
    }

    function handleUpdateLocation(page: number, pdfCoords: Point, pdfCoords2?: Point) {
        if (positionSelection == null) return;
        setMarkerLocation(positionSelection, page, pdfCoords, pdfCoords2);
    }

    function handleAddMarker(pdfCoords: Point, pdfCoords2?: Point) {
        if (positionSelection == null) return;
        addMarker(positionSelection, pdfCoords, pdfCoords2);
    }

    function handleClose() {
        navigate('/protocol-list');
    }


    const activePosition = positionSelection ? protocol?.positions[positionSelection.positionIdx] : null;
    const shouldShowImagePane = activePosition?.type === 'visualInspection';
    const positionImage = shouldShowImagePane && (activePosition?.imageFile ?? savedPositionImages?.[positionSelection.positionIdx]);

    if (isProtocolLoading) return <div>Loading...</div>;
    if (protocolError) return <div>An error has occurred: {protocolError.message}</div>;
    return (
        <div className='measurement-container'>
            <ProtocolPdf
                className='measurement-pdf-container'
                isViewMode={isViewMode}
                positions={protocol?.positions}
                positionSelection={positionSelection}
                showAllPositions={showAllPositions}
                pdfContent={pdfData}
                isPdfLoading={isPdfLoading}
                onAddPosition={handleAddPosition}
                onUpdateLocation={handleUpdateLocation}
                onAddMarker={handleAddMarker}
            />

            <div className='measurement-form-pane'>
                {protocol && <ProtocolForm
                    className='measurement-form-top'
                    mode={mode}
                    protocol={protocol}
                    setProtocol={setProtocol}
                    pdfData={pdfData}
                    setPdfData={setPdfData}
                    positionSelection={positionSelection}
                    setPositionSelection={setPositionSelection}
                    showAllPositions={showAllPositions}
                    setShowAllPositions={setShowAllPositions}
                    isDirty={isDirty}
                    setIsDirty={setIsDirty}
                    isSaving={isSaving}
                    onDraftSave={handleDraftSave}
                    onFinalSave={handleFinalSave}
                    onReviewSave={handleReviewSave}
                    onCloseClicked={handleClose}
                />}

                {shouldShowImagePane && <VisualInspectionImage
                    className='measurement-form-bottom'
                    imageData={positionImage}
                    isViewMode={isViewMode}
                    onImageChange={(file) => setPositionImage(positionSelection!.positionIdx, file)}
                />}
            </div>
        </div>
    );
}
