import * as d3 from 'd3';
import { MetaData } from './getMetaData';
import { DataPoint } from './transformDataPoints';
import { formatDatetime } from '../../utils/fieldFormats';
// import { formatDatetime } from '../../utils/fieldFormats';

type SVGElement = d3.Selection<SVGGElement, unknown, null, undefined>;
// type GElement = d3.Selection<SVGGElement, unknown, null, undefined>;


export default function drawMeasurementGraph(
    container: HTMLDivElement,
    dataPoints: DataPoint[],
    metaData: MetaData,
    drawBorder: boolean = true,
) {
    //Create views, set their sizes
    const rect = container.getBoundingClientRect();
    const contentWidth = rect.width;
    const contentHeight = rect.height;

    let root = d3.select(container).select('svg') as SVGElement;
    if (root.empty()) {
        root = d3.select(container)
            .append('svg')
            .attr('width', contentWidth)
            .attr('height', contentHeight)
            .attr('class', 'graph')
        // .attr('border', '1px solid #f77');
    } else {
        root.html('');
    }

    if (!drawBorder) {
        root.style('border', 'none');
    } else if (metaData.isLastValueFlagged) {
        root.style('border', '2px solid red');
    } else {
        root.style('border', '2px solid #aaa');
    }

    const graphMargins = { top: 15, right: 20, bottom: 30, left: 50 };
    const graphWidth = +root.attr('width') - graphMargins.left - graphMargins.right;
    const graphHeight = +root.attr('height') - graphMargins.top - graphMargins.bottom;

    let graph = root.select('g');
    if (graph.empty()) {
        graph = root.append('g').attr('transform', `translate(${graphMargins.left}, ${graphMargins.top})`);
    }

    // Create x and y scales
    const x = d3
        .scalePoint()
        .range([0, graphWidth])
        .domain(dataPoints.map((_, index) => '' + index));
    root.append('g')
        .attr('transform', `translate(${graphMargins.left}, ${graphHeight + graphMargins.top})`)
        .call(d3.axisBottom(x).tickFormat((_, i) => '' + dataPoints[i].partCount))
        .selectAll('text')
        .style('font-size', getTextSize(contentWidth) + 'px');

    const y = d3
        .scaleLinear()
        .range([graphHeight, 0])
        .domain([metaData.lowerGraphLimit, metaData.upperGraphLimit]);
    root.append('g')
        .attr('transform', `translate(${graphMargins.left}, ${graphMargins.top})`)
        .call(d3.axisLeft(y))
        .selectAll('text')
        .style('font-size', getTextSize(contentWidth) + 'px');

    //Nominal, part tolerances
    const lineThickness = 1;
    graph.append('line')
        .attr('x1', 0)
        .attr('y1', y(metaData.nominal) + lineThickness / 2)
        .attr('x2', graphWidth)
        .attr('y2', y(metaData.nominal) + lineThickness / 2)
        .attr('stroke', '#000000')
        .attr('stroke-width', lineThickness);
    graph.append('line')
        .attr('x1', 0)
        .attr('y1', y(metaData.lowerMeasureLimit) + lineThickness / 2)
        .attr('x2', graphWidth)
        .attr('y2', y(metaData.lowerMeasureLimit) + lineThickness / 2)
        .attr('stroke', '#46A346')
        .attr('stroke-width', lineThickness);
    graph.append('line')
        .attr('x1', 0)
        .attr('y1', y(metaData.upperMeasureLimit) + lineThickness / 2)
        .attr('x2', graphWidth)
        .attr('y2', y(metaData.upperMeasureLimit) + lineThickness / 2)
        .attr('stroke', '#46A346')
        .attr('stroke-width', lineThickness);

    //Mean line
    graph.append('line')
        .attr('x1', 0)
        .attr('y1', y(metaData.mean) + lineThickness / 2)
        .attr('x2', graphWidth)
        .attr('y2', y(metaData.mean) + lineThickness / 2)
        .attr('stroke', '#46A346')
        .attr('stroke-width', lineThickness)
        .attr('stroke-dasharray', '5,5');

    //Sigma lines
    // if (metaData.lcl != null && metaData.ucl != null) {
    //     graph.append('line')
    //         .attr('x1', 0)
    //         .attr('y1', y(metaData.lcl) + lineThickness / 2)
    //         .attr('x2', graphWidth)
    //         .attr('y2', y(metaData.lcl) + lineThickness / 2)
    //         .attr('stroke', '#5252FF')
    //         .attr('stroke-width', lineThickness)
    //         .attr('stroke-dasharray', '5,5');

    //     graph.append('line')
    //         .attr('x1', 0)
    //         .attr('y1', y(metaData.ucl) + lineThickness / 2)
    //         .attr('x2', graphWidth)
    //         .attr('y2', y(metaData.ucl) + lineThickness / 2)
    //         .attr('stroke', '#5252FF')
    //         .attr('stroke-width', lineThickness)
    //         .attr('stroke-dasharray', '5,5');
    // }


    //Measurement values

    //Create tooltip element for showing extra detail on hover
    let tooltip = d3.select('#tooltip');
    if (tooltip.empty()) {
        tooltip = d3.select(container)
            .append('div')
            .attr('id', 'tooltip')
            .style('position', 'absolute')
            .style('visibility', 'hidden')
            .style('background-color', 'white')
            .style('padding', '5px')
            .style('border', '1px solid #ccc')
            .style('text-align', 'center')
            .style('font-size', '12px')
            .style('pointer-events', 'none')
            .style('top', '100px')//Make sure the initial position is within window bounds
            .style('left', '100px')
            ;
    }
    //Draw connecting lines between points
    for (let idx = 0; idx < dataPoints.length - 1; idx++) {
        if (!dataPoints[idx + 1].shouldDraw) continue;
        const lineGenerator = d3.line<DataPoint>()
            .x((_, i: number) => x(idx + i + ''))
            .y((d: DataPoint, i: number) => y(Math.max(metaData.lowerValueLimit, Math.min(metaData.upperValueLimit, d.value))));

        const useRedLine = dataPoints[idx].failedSpcCheck && dataPoints[idx + 1].failedSpcCheck;
        graph.append('path')
            .datum([dataPoints[idx], dataPoints[idx + 1]])
            .attr('fill', 'none')
            .attr('stroke', useRedLine ? '#ff0000' : '#10A37F')
            .attr('stroke-width', 3)
            .attr('d', lineGenerator);
    }

    //Draw datapoints
    graph.selectAll('.point')
        .data(dataPoints.filter(it => it.shouldDraw))
        .enter().append('circle')
        .attr('class', 'point')
        .attr('cx', (d: DataPoint, i: number) => x(i + '') + x.bandwidth() / 2) // Centers the circle in the band
        .attr('cy', (d: DataPoint, i: number) => y(Math.max(metaData.lowerValueLimit, Math.min(metaData.upperValueLimit, d.value))))
        .attr('r', 5)
        .attr('fill', (d: DataPoint, i: number) => d.failedSpcCheck ? '#ff0000' : '#10A37F')

        //Extra detail on hover:
        .on('mouseover', (event, d: DataPoint) => {
            tooltip.style('visibility', 'visible')
                .text(`${d.valueAsString} - ${formatDatetime(d.measurementStart)}`);
        })
        .on('mousemove', (event) => {
            // tooltip.style('top', (event.pageY - 10) + 'px')
            //     .style('left', (event.pageX + 10) + 'px');
            const tooltipWidth = parseInt(tooltip.style('width'), 10);
            const tooltipHeight = parseInt(tooltip.style('height'), 10);
            const padding = 10;

            // Calculate new position
            let xPosition = event.pageX + padding;
            let yPosition = event.pageY - padding;

            // Adjust if tooltip overflows the right edge
            if (xPosition + tooltipWidth > window.innerWidth) {
                xPosition = event.pageX - tooltipWidth - padding;
            }

            // Adjust if tooltip overflows the bottom edge
            if (yPosition + tooltipHeight > window.innerHeight) {
                yPosition = event.pageY - tooltipHeight - padding;
            }

            tooltip.style('top', yPosition + 'px')
                .style('left', xPosition + 'px');
        })
        .on('mouseout', () => {
            tooltip.style('visibility', 'hidden');
        });
}

function getTextSize(containerWidth: number): number {
    if (containerWidth < 750) {
        return 10;
    } else {
        return 12;
    }
}
