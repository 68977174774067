import { useNavigate } from 'react-router-dom';


interface ProtocolListInputsProps {
    isLoading: boolean;
    error: any;
    showDisabled: boolean;
    filterInput: string;
    setShowDisabled: (value: boolean) => void;
    setFilterInput: (value: string) => void;
    onShowMappingsClick: () => void;
}

export default function ProtocolListInputs({
    isLoading,
    error,
    showDisabled,
    filterInput,
    setShowDisabled,
    setFilterInput,
    onShowMappingsClick,
}: ProtocolListInputsProps) {

    const navigate = useNavigate();

    const handleCreateClick = () => {
        navigate(`/protocol-view?mode=edit`);
    };

    const handleClearFilters = () => {
        setShowDisabled(false);
        setFilterInput('');
    }


    if (isLoading) return <div>Loading...</div>;
    if (error) return <div>An error has occurred: {error.message}</div>;
    return (
        <div style={{ display: 'flex' }}>
            <button onClick={handleCreateClick}>Create protocol</button>
            <button onClick={onShowMappingsClick}>Show mappings</button>

            <div className='flex__spacer' />
            <label className='with-checkbox'>
                Show disabled
                <input
                    style={{ marginLeft: '8px' }}
                    type='checkbox'
                    checked={showDisabled}
                    onChange={e => setShowDisabled(e.target.checked)}
                />
            </label>
            <input
                style={{ marginLeft: '18px' }}
                type='text'
                placeholder='Search'
                value={filterInput}
                onChange={e => setFilterInput(e.target.value)}
            />
            <button className='button--clear-filters' onClick={(e) => { e.stopPropagation(); handleClearFilters(); }}>
                <img src='/clear-filter-icon.svg' alt='Clear filters' />
            </button>
        </div>
    );
}
