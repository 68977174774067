import { useFetchOperators } from '../hooks/fetchHooks';
import { Operator } from '../types/sharedTypeImpl';


interface OperatorProps {
    className?: string;
    operatorInitials?: string;
    required?: boolean;
    disabled?: boolean;
    readOnly?: boolean;
    onChange?: (operatorInitials: string) => void;
}

export default function OperatorSelect({
    className,
    operatorInitials,
    required,
    disabled,
    readOnly,
    onChange,
}: OperatorProps) {

    const { data: operators, isLoading } = useFetchOperators();
    const usingUnsavedOperator = operatorInitials && !operators?.find(
        (it) => it.initials.toUpperCase() === operatorInitials.toUpperCase()
    );
    const options = [
        ...operators ?? [],
        ...(usingUnsavedOperator ? [{ initials: operatorInitials.toUpperCase() } as Operator] : [])
    ];

    const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        onChange?.(event.target.value);
    }


    if (isLoading && operatorInitials) {
        return (
            <select className={className}>
                <option>{operatorInitials ?? ''}</option>
            </select>
        );
    }

    return (
        <select
            className={className}
            style={{ pointerEvents: readOnly ? 'none' : 'auto' }}
            disabled={disabled ?? false}
            required={required}
            value={operatorInitials?.toUpperCase()}
            onChange={handleChange}
        >
            <option value='' disabled hidden />
            {options?.map((it) => (
                <option key={it.initials.toUpperCase()} value={it.initials.toUpperCase()}>
                    {it.initials}{it.fullName && ` - ${it.fullName}`}
                </option>
            ))}
        </select>
    )
}
