import { Link } from 'react-router-dom';
import { encodeQueryData } from '../../utils/fetchRequests';
import { formatBatchTimeRemaining, formatCycleTime, formatDuration } from '../../utils/fieldFormats';
import { openLinkInNewTab } from '../../utils/navigation';
import { getSessionTimeRemainingStyle } from '../../utils/styles';
import { MeasurementIntervalState } from '../../types/sharedTypeImpl';


interface MeasurementIntervalTableProps {
    data: MeasurementIntervalState[],
    handlePartCountClicked: (row: MeasurementIntervalState) => void,
    handleMeasurePressed: (row: MeasurementIntervalState) => void,
}

export default function MeasurementIntervalTable({
    data,
    handlePartCountClicked,
    handleMeasurePressed,
}: MeasurementIntervalTableProps) {

    const cycleTimeHelp = 'Latest stable cycle time (5 parts produced in a row with the same time)';

    function isHelpMeStatus(row: MeasurementIntervalState) {
        return row.hasDisruption && !row.fixInProgress;
    }


    return (
        <table>
            <thead>
                <tr>
                    <th>Equipment name</th>
                    <th>Part name</th>
                    <th>Parts count</th>
                    <th>Measure interval</th>
                    <th title={cycleTimeHelp}>Cycle time</th>
                    <th>Batch time</th>
                    <th>Interval time</th>
                    <th>Last measured</th>
                    <th>Status</th>
                    <th />
                </tr>
            </thead>
            <tbody>
                {data?.map(row =>
                    <tr key={row.equipment} className={(isHelpMeStatus(row)) ? 'highlight--issue' : ''}>
                        <EquipmentName row={row} />
                        <ProductName row={row} />
                        <PartsCount row={row} handlePartCountClicked={handlePartCountClicked} />
                        <MeasureInterval row={row} />
                        <CycleTime row={row} />
                        <SessionTime row={row} />
                        <IntervalTime row={row} />
                        <LastMeasured row={row} />
                        <Status row={row} />
                        <MeasureButton row={row} handleMeasurePressed={handleMeasurePressed} />
                    </tr>
                )}
            </tbody>
        </table>
    )
}


function EquipmentName({ row }: { row: MeasurementIntervalState }) {
    return (
        <td className='textAlignLeft'>
            <Link className='inheritColor' to={`/machine-log#equipment=${row.equipment}`}>
                {row.displayName}
            </Link>
        </td>
    );
}

function ProductName({ row }: { row: MeasurementIntervalState }) {
    return (
        <td className='textAlignLeft' title={row.product}>
            {row.partDisplayName}
        </td>
    );
}

function PartsCount({
    row,
    handlePartCountClicked
}: {
    row: MeasurementIntervalState,
    handlePartCountClicked: (row: MeasurementIntervalState) => void
}) {
    const partCountText = [
        ...(row.partCount != null ? [row.partCount] : []),
        ...(row.partCount != null && row.targetQuantity ? [row.targetQuantity] : []),
    ].join(' / ');
    const className = partCountText.length > 0 ? 'editable' : '';

    return (
        <td className={className} onClick={e => handlePartCountClicked(row)}>
            {partCountText}
        </td>
    );
}

function MeasureInterval({ row }: { row: MeasurementIntervalState }) {
    const showMeasureInterval = row.measureIntervalCount != null && row.measureIntervalTarget != null;

    return (
        <td>{showMeasureInterval && `${row.measureIntervalCount} / ${row.measureIntervalTarget}`}</td>
    );
}

function CycleTime({ row }: { row: MeasurementIntervalState }) {
    return (
        <td>{row.cycleTime != null && formatCycleTime(row.cycleTime)}</td>
    );
}

function SessionTime({ row }: { row: MeasurementIntervalState }) {
    const sessionTimeRemaining = +row.sessionEndPrediction - +new Date();
    const cellStyle = getSessionTimeRemainingStyle(sessionTimeRemaining, row.partCount, row.targetQuantity, row.inOfflineMode);

    return (
        <td style={cellStyle}>
            {row.sessionEndPrediction != null && formatBatchTimeRemaining(sessionTimeRemaining)}
        </td>
    );
}

function IntervalTime({ row }: { row: MeasurementIntervalState }) {
    const warningPeriodMillis = 45 * 60 * 1000;
    const intervalTimeRemaining = +row.intervalPrediction - +new Date();
    const considerCount = row.measureIntervalTarget > 0 && !(row.requiresConfirmMeasure || row.inOfflineMode);
    const countReached = row.measureIntervalCount >= row.measureIntervalTarget;
    const timerReached = intervalTimeRemaining <= 0;
    const requiresMeasuring = considerCount ? countReached : timerReached;

    let intervalTimeStyle: React.CSSProperties = {};
    if (requiresMeasuring) {
        intervalTimeStyle = { color: 'red' };
    } else if (intervalTimeRemaining <= warningPeriodMillis) {
        intervalTimeStyle = { color: 'orange' };
    }

    return (
        <td style={intervalTimeStyle}>
            {row.intervalPrediction != null && formatDuration(intervalTimeRemaining, true, false)}
        </td>
    );
}

function LastMeasured({ row }: { row: MeasurementIntervalState }) {
    return (
        <td>{
            row.lastMeasureTime?.toLocaleTimeString([], {
                weekday: 'short', hour: '2-digit', hour12: false, minute: '2-digit'
            })
        }</td>
    );
}

function Status({ row }: { row: MeasurementIntervalState }) {
    let statusText: string | null = null;
    if (row.inOfflineMode) {
        statusText = 'MT-LINKi not available';
    } else if (row.isSetupComplete == false) {
        statusText = 'In setup';
    } else if (row.hasDisruption) {
        let disruptionDurationText = '';
        if (row.disruptionStatusStart) {
            const startTime = row.disruptionStatusStart;
            const duration = (new Date().getTime() - startTime.getTime());
            const durationFormatted = formatDuration(duration, false, true);
            disruptionDurationText = `(${durationFormatted})`
        }
        const mainStatusText = row.fixInProgress ? 'Fix in progress' : 'Help me!';
        statusText = `${mainStatusText} ${disruptionDurationText}`;
    } else if (row.requiresConfirmMeasure) {
        statusText = 'Confirmation measurement';
    }

    return (
        <td>{statusText}</td>
    );
}

function MeasureButton({
    row,
    handleMeasurePressed
}: {
    row: MeasurementIntervalState,
    handleMeasurePressed: (row: MeasurementIntervalState) => void
}) {

    const handleContinueMeasurement = () => {
        openLinkInNewTab(`record-view` + encodeQueryData({ id: row.ongoingRecordId, mode: 'edit' }));
    }

    return (
        <td>
            {row.ongoingRecordId == null
                ? <button onClick={() => handleMeasurePressed(row)}>Measure</button>
                : <button onClick={handleContinueMeasurement}>Continue</button>
            }
        </td>
    );
}
