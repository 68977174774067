
export function removeElementByIndex<T>(array: T[], index: number): T[] {
    return [
        ...array.slice(0, index),
        ...array.slice(index + 1)
    ];
}

export function mapBy<T, K>(
    array: T[],
    keyFunction: (value: T) => K
): Map<K, T[]> {
    const map = new Map<K, T[]>();

    array.forEach((item) => {
        const key = keyFunction(item);
        const collection = map.get(key);

        if (collection) {
            collection.push(item);
        } else {
            map.set(key, [item]);
        }
    });

    return map;
}

export function groupBy<T, K>(array: T[], keyFunction: (value: T) => K): T[][] {
    const map = mapBy(array, keyFunction);
    const valuesArray = Array.from(map.values());
    return valuesArray;
}

export function sortBy<T>(array: T[], valueFunction: (value: T) => any): T[] {
    return array.sort((a, b) => {
        const valueA = valueFunction(a);
        const valueB = valueFunction(b);

        if (valueA == null && valueB == null) {
            return 0;
        } else if (valueA == null) {
            return -1;
        } else if (valueB == null) {
            return 1;
        } else if (typeof valueA === 'number' && typeof valueB === 'number') {
            return valueA - valueB;
        } else if (valueA instanceof Date && valueB instanceof Date) {
            return valueA.getTime() - valueB.getTime();
        } else {
            return valueA.toString().localeCompare(valueB.toString());
        }
    });
}

export function sortByDesc<T>(array: T[], valueFunction: (value: T) => any): T[] {
    return sortBy(array, valueFunction).reverse();
}

export function minBy<T>(
    array: T[],
    valueFunction: (value: T) => number
): T | undefined {
    if (array.length === 0) return undefined;

    const out = array.reduce((min, current) => {
        return min == null || valueFunction(current) < valueFunction(min) ? current : min;
    }, array[0]);
    return out;
}

export function maxBy<T>(
    array: T[],
    valueFunction: (value: T) => number
): T | undefined {
    if (array.length === 0) return undefined;

    const out = array.reduce((max, current) => {
        return max == null || valueFunction(current) > valueFunction(max) ? current : max;
    }, array[0]);
    return out;
}

export function maxOf<T>(
    array: T[],
    valueFunction: (value: T) => number
): number | undefined {
    if (array.length === 0) return undefined;

    const out = array.reduce((maxValue, currentElement) => {
        const currentValue = valueFunction(currentElement);
        return maxValue == null || currentValue > maxValue ? currentValue : maxValue;
    }, valueFunction(array[0]));
    return out;
}

export function minOf<T>(
    array: T[],
    valueFunction: (value: T) => number
): number | undefined {
    if (array.length === 0) return undefined;

    const out = array.reduce((minValue, currentElement) => {
        const currentValue = valueFunction(currentElement);
        return currentValue < minValue ? currentValue : minValue;
    }, valueFunction(array[0]));
    return out;
}

export function sumOf<T>(
    array: T[],
    valueFunction: (value: T) => number
): number | undefined {
    if (array.length === 0) return undefined;

    const out = array.reduce((sum, currentElement) => {
        return sum + valueFunction(currentElement);
    }, 0);
    return out;
}

export function mapToArrayOfKeyVal<K, V>(map: Map<K, V>, keyPropName: string, valuePropName: string) {
    return Array.from(map.entries()).map(([key, value]) => ({
        [keyPropName]: key,
        [valuePropName]: value
    }));
}

export function chunkArray<T>(array: T[], chunkSize: number): T[][] {
    const chunked: T[][] = [];
    for (let i = 0; i < array.length; i += chunkSize) {
        const chunk = array.slice(i, i + chunkSize);
        chunked.push(chunk);
    }
    return chunked;
}
