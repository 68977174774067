import { useEffect, useState } from 'react';
import { postMaintenanceOperation } from '../../api/requests';
import ModalDialog from '../../components/modalDialog';
import OperatorSelect from '../../components/operatorSelect';


interface AddMaintenanceOpDialogProps {
    equipment: string;
    isOpen: boolean;
    onOperationAdded: () => void;
    onClose: () => void;
}

export default function AddMaintenanceOpDialog({
    equipment,
    isOpen,
    onOperationAdded,
    onClose,
}: AddMaintenanceOpDialogProps) {

    const [comment, setComment] = useState('');
    const [operator, setOperator] = useState('');


    useEffect(() => {
        if (!isOpen) return;
        setComment('');
        setOperator('');
    }, [isOpen]);

    const handleSubmit = async (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        await postMaintenanceOperation({
            equipment,
            comment,
            operator,
        });
        onOperationAdded();
        onClose();
    }


    return (
        <ModalDialog
            isOpen={isOpen}
            frameClassName='modal-frame--operation-dialog'
            contentClassName='modal-content--tablet'
            onCancel={onClose}
        >
            <div>
                <label>Comment</label>
                <textarea
                    className='dialog-form__input'
                    rows={3}
                    value={comment}
                    onChange={(e) => setComment(e.target.value)}
                />
            </div>
            <div className='dialog-form__field--with-margins'>
                <label>Operator</label>
                <OperatorSelect
                    className='dialog-form__input'
                    operatorInitials={operator}
                    onChange={(operatorInitials) => setOperator(operatorInitials)}
                />
            </div>

            <div className='dialog--button-bar'>
                <button className='button--large' onClick={handleSubmit}>Submit</button>
            </div>
        </ModalDialog>
    )
}
