import TriStateToggle, { TriStateToggleProps } from './triStateToggle';


interface ToggleButtonProps extends TriStateToggleProps {
    selection: boolean;
}

export default function ToggleButton(options: ToggleButtonProps) {
    return (
        <TriStateToggle {...options} />
    );
}
