import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import { sortByDesc } from '../../../../shared/utils/arrayUtils';
import { fetchOperations } from '../../api/requests';
import NavBar from '../../components/navBar';
import OperationDialog from '../../components/operation-dialog/operationDialog';
import OperationsTable from '../../components/operation-table/operationsTable';
import ScrapCloseDialog from '../../components/scrapCloseDialog';
import { Operation } from '../../types/sharedTypeImpl';


export default function PotentialScraps() {

    const [operationUnderEdit, setOperationUnderEdit] = useState<Operation | null>(null);
    const [operationUnderScrapClose, setOperationUnderScrapClose] = useState<Operation | null>(null);

    const sevenDaysAgo = new Date(+new Date() - 7 * 24 * 3_600_000);


    const { data: pendingOperations, refetch: refetchPending } = useQuery({
        queryKey: ['operations', 'pending'],
        queryFn: () => fetchOperations({ hasPendingPotentialScrap: true }),
        select: (operations) => sortByDesc([...operations], it => it.time)
    });

    const { data: handledOperations, refetch: refetchHandled } = useQuery({
        queryKey: ['operations', 'handled'],
        queryFn: () => fetchOperations({ hasScrapCloseForm: true, minScrapCloseTime: sevenDaysAgo }),
        select: (operations) => sortByDesc([...operations], it => it.scrapCloseForm.time)
    });


    const handleOperationEditClick = (operation: Operation) => {
        if (operation.scrapCloseForm) {
            setOperationUnderScrapClose(operation);
        } else {
            setOperationUnderEdit(operation);
        }
    }

    const handleScrapCloseClick = (operation: Operation) => {
        setOperationUnderScrapClose(operation);
    }

    const refetch = () => {
        refetchPending();
        refetchHandled();
    }


    return (
        <>
            <NavBar />
            <div className='content'>
                {pendingOperations == null
                    ? 'Loading...'
                    : <>
                        <h2>Pending potential scraps</h2>
                        <OperationsTable
                            narrow={false}
                            operations={pendingOperations}
                            showSession={true}
                            operationTypeIndicators={false}
                            highlightPotentialDiscards={false}
                            showScrapLabels={true}
                            showOrderNumbers={true}
                            isEditableIfScrapClosed={true}
                            enableScrapClose={true}
                            showScrapCloseValues={false}
                            onEditClick={handleOperationEditClick}
                            onScrapCloseClick={handleScrapCloseClick}
                        />
                    </>
                }

                {handledOperations && <>
                    <h2 style={{ marginTop: '40px' }}>Handled potential scraps</h2>
                    <OperationsTable
                        narrow={false}
                        operations={handledOperations}
                        showSession={true}
                        operationTypeIndicators={false}
                        highlightPotentialDiscards={false}
                        showScrapLabels={false}
                        showOrderNumbers={true}
                        isEditableIfScrapClosed={true}
                        enableScrapClose={false}
                        showScrapCloseValues={true}
                        onEditClick={handleOperationEditClick}
                    />
                </>
                }
            </div>

            <OperationDialog
                isOpen={operationUnderEdit != null}
                operationUnderEdit={operationUnderEdit}
                onClose={() => setOperationUnderEdit(null)}
                onOperationSaved={() => refetch()}
            />
            {operationUnderScrapClose && <ScrapCloseDialog
                isOpen={true}
                operation={operationUnderScrapClose}
                onClose={() => setOperationUnderScrapClose(null)}
                onSuccess={() => refetch()}
            />}
        </>
    )
}
