import { useState } from 'react';
import { fetchProductComment } from '../../api/requests';
import useFetchOnce from '../../hooks/useFetchOnce';
import ProductCommentDialog from './productCommentDialog';
import { SelectedPart } from './selectedPart';


interface ProductCommentProps {
    selectedPart: SelectedPart | null;
}

export default function ProductComment({
    selectedPart,
}: ProductCommentProps) {

    const [isProductCommentDialogOpen, setIsProductCommentDialogOpen] = useState(false);


    const { data: productComment, refetch } = useFetchOnce({
        queryKey: ['productComment', selectedPart?.partName],
        queryFn: async () => fetchProductComment(selectedPart!.partName),
        enabled: !!selectedPart?.partName,
    });


    return (
        <>
            <div style={{ display: 'flex', marginTop: '8px' }}>
                <textarea id='productCommentText' value={productComment ?? ''} readOnly />
                <button
                    disabled={selectedPart?.partName == null}
                    onClick={() => setIsProductCommentDialogOpen(true)}
                >
                    Edit
                </button>
            </div>

            {isProductCommentDialogOpen && <ProductCommentDialog
                partName={selectedPart!.partName}
                product={selectedPart!.product}
                initialProductComment={productComment}
                onSubmited={() => refetch()}
                onClose={() => setIsProductCommentDialogOpen(false)}
            />}
        </>
    );
}
