import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { Point } from '../types';


export interface MenuItem {
    text: string;
    isEnabled?: boolean;
    callback: () => void;
}

export interface ContextMenuProps {
    isOpen: boolean
    position: Point;
    items: MenuItem[];
    close: () => void;
}

export function ContextMenu({
    isOpen,
    position,
    items,
    close,
}: ContextMenuProps) {
    const menuRef = useRef<HTMLDivElement>(null);
    const [finalPosition, setFinalPosition] = useState<Point>(position);

    //Menu is first drawn invisibly, to be able to measure it, and make sure it doesn't extend offscreen
    const [isVisible, setIsVisible] = useState<boolean>(false);


    //Close when clicked outside
    useEffect(() => {
        function handleOutsideClick(event: MouseEvent) {
            if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
                close();
            }
        }

        document.addEventListener('mousedown', handleOutsideClick);
        return () => document.removeEventListener('mousedown', handleOutsideClick);
    }, [close]);

    //Adjust context menu position and make visible
    useLayoutEffect(() => {
        const menuElement = menuRef.current;
        if (menuElement) {
            const menuWidth = menuElement.offsetWidth;
            const menuHeight = menuElement.offsetHeight;
            const viewportWidth = window.innerWidth;
            const viewportHeight = window.innerHeight;
            const newPosition = { ...position };

            if (position.x + menuWidth > viewportWidth) {
                newPosition.x = Math.max(viewportWidth - menuWidth, 0);
            }
            if (position.y + menuHeight > viewportHeight) {
                newPosition.y = Math.max(viewportHeight - menuHeight, 0);
            }

            setFinalPosition(newPosition);
            setIsVisible(true);
        }
    }, [position, items]);

    const handleItemClick = (e: React.MouseEvent, callback: () => void) => {
        e.preventDefault();
        callback();
        close();
    };


    if (!isOpen) return null;
    return (
        <div
            ref={menuRef}
            className='context-menu-wrapper'
            style={{
                left: `${finalPosition.x}px`,
                top: `${finalPosition.y}px`,
                position: 'fixed',
                outline: 'none',
                display: isVisible ? 'block' : 'hidden',
            }}
            tabIndex={-1}
            onContextMenu={(e) => e.preventDefault()}
        >
            <ul className='context-menu'>
                {items
                    .filter(it => it.isEnabled === undefined || it.isEnabled)
                    .map(it => (
                        <li key={it.text} style={{ whiteSpace: 'nowrap' }}>
                            <a href='#' onClick={(e) => handleItemClick(e, it.callback)}>
                                {it.text}
                            </a>
                        </li>
                    ))}
            </ul>
        </div>
    )
}
