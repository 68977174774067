import { useState, useEffect } from 'react';


export const useFullScreenToggle = () => {
    const [isFullscreen, setIsFullscreen] = useState<boolean>(document.fullscreenElement ? true : false);

    useEffect(() => {
        const toggleFullScreen = () => {
            if (!document.fullscreenElement) {
                document.documentElement.requestFullscreen().catch((err) => {
                    console.error(`Error attempting to enable full-screen mode: ${err.message} (${err.name})`);
                });
            } else {
                if (document.exitFullscreen) {
                    document.exitFullscreen();
                }
            }
        };

        const handleFullScreenChange = () => {
            setIsFullscreen(document.fullscreenElement ? true : false);
        };

        const handleKeyDown = (e: KeyboardEvent) => {
            if (e.key === 'F11') {
                e.preventDefault();
                toggleFullScreen();
            }
        };

        document.addEventListener('keydown', handleKeyDown);
        document.addEventListener('fullscreenchange', handleFullScreenChange);

        return () => {
            document.removeEventListener('keydown', handleKeyDown);
            document.removeEventListener('fullscreenchange', handleFullScreenChange);
        };
    }, []);

    return { isFullscreen };
};
