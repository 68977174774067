import { Operation } from '../../types/sharedTypeImpl';
import { OperationDraft } from './operationDraft'


export enum OperationTypeOptions {
    Adjustments = 'Adjustments',
    Offset = 'Offset',
    ToolChange = 'Tool change',
    ProgramEdit = 'Program edit',
    FixToolChange = 'Fix unscheduled tool change',
}

export enum MachineComponentOptions {
    Main = 'Main',
    SubCollet = 'Sub collet',
    Barfeeder = 'Barfeeder',
}

export enum MachineMainComponentOptions {
    Guidebush = 'Guidebush',
    Collet = 'Collet',
}

export enum PlusOrMinusOptions {
    Plus = '+',
    Minus = '-',
}

export enum ToolVariantTypeOptions {
    Main = 'Main',
    Sub = 'Sub'
}

export enum DimensionOptions {
    X = 'x',
    Y = 'y',
    Z = 'z',
    R = 'r',
}

export enum IsScheduledOptions {
    Scheduled = 'Scheduled',
    NotScheduled = 'Not scheduled',
}

export enum ToolFailReasonOptions {
    Worn = 'Worn',
    Breakage = 'Breakage',
}

export enum ToolBreakReasonOptions {
    Pikcup = 'Pickup',
    Guidebush = 'Guidebush',
    Lubrication = 'Lubrication',
    FeedSpeed = 'Feed/speed',
    Chips = 'Chips',
    Other = 'Other',
}


interface FormNode {
    fieldKey: keyof OperationDraft;
    child?: FormNode;
    children?: Record<string, FormNode | null>;
}

const treeSubForms: FormNode = {
    fieldKey: 'operationType', children: {
        [OperationTypeOptions.Adjustments]: {
            fieldKey: 'machineComponent', children: {
                [MachineComponentOptions.Main]: {
                    fieldKey: 'machineMainComponent', child: {
                        fieldKey: 'plusOrMinus',
                    }
                },
                [MachineComponentOptions.SubCollet]: {
                    fieldKey: 'plusOrMinus',
                },
                [MachineComponentOptions.Barfeeder]: {
                    fieldKey: 'adjustmentText',
                },
            }
        },
        [OperationTypeOptions.Offset]: {
            fieldKey: 'toolVariant', child: {
                fieldKey: 'toolNumber', child: {
                    fieldKey: 'dimension', child: {
                        fieldKey: 'offset',
                    }
                }
            }
        },
        [OperationTypeOptions.ToolChange]: {
            fieldKey: 'toolVariant', child: {
                fieldKey: 'toolNumber', child: {
                    fieldKey: 'collateralDamage', child: {
                        fieldKey: 'isScheduled', children: {
                            [IsScheduledOptions.Scheduled]: null,
                            [IsScheduledOptions.NotScheduled]: {
                                fieldKey: 'toolFailReason', children: {
                                    [ToolFailReasonOptions.Worn]: null,
                                    [ToolFailReasonOptions.Breakage]: {
                                        fieldKey: 'toolBreakReason',
                                    }
                                }
                            }
                        }
                    }
                }
            }
        },
        [OperationTypeOptions.ProgramEdit]: {
            fieldKey: 'programEditText',
        },
        [OperationTypeOptions.FixToolChange]: {
            fieldKey: 'fixedTools',
        },
    }
}


export function getFormIds(operation: OperationDraft)
    : {
        formIds: Array<keyof Operation>,
        isFormComplete: boolean,
    } {

    const formIds: Array<keyof OperationDraft> = [];
    let node: FormNode = treeSubForms;
    while (true) {
        formIds.push(node.fieldKey);

        if (node.child != null) {
            node = node.child;
        } else if (node.children != null) {
            const fieldKey = node.fieldKey;
            const fieldValue = operation[fieldKey] as string;
            if (fieldValue == null) {
                return { formIds, isFormComplete: false };
            }

            const nextNode = node.children[fieldValue];
            if (nextNode == null) {
                return { formIds, isFormComplete: true };
            }
            node = nextNode;
        } else {
            return { formIds, isFormComplete: true };
        }
    }
}
