import { Point } from '../types';


export function calcStddev(values: number[]): number {
    // Step 1: Calculate the mean
    const mean = calcMean(values);

    // Step 2 & 3: Calculate the squared differences from the mean and find their mean
    const squaredDifferences = values.map(value => {
        const difference = value - mean;
        return difference ** 2;
    });
    const meanOfSquaredDifferences = squaredDifferences.reduce((acc, val) => acc + val, 0) / squaredDifferences.length;

    // Step 4: Take the square root of the mean of squared differences
    return Math.sqrt(meanOfSquaredDifferences);
}

export function calcMean(values: number[]): number {
    return values.reduce((acc, val) => acc + val, 0) / values.length;
}

export function calcDistance(a: Point, b: Point): number {
    return Math.sqrt((a.x - b.x) ** 2 + (a.y - b.y) ** 2);
}