

export async function saveCsvWithPicker(filename: string, data: string | Blob) {
    const dataBlob = typeof data === 'string'
        ? new Blob([data], { type: 'text/csv' })
        : data;

    if ('showSaveFilePicker' in window) {
        const opts = {
            suggestedName: filename,
            types: [{ description: 'CSV Files', accept: { 'text/csv': ['.csv'] }, },],
        };
        try {
            // Prompt user to select a location and filename for the CSV file
            const handle = await (window as any).showSaveFilePicker(opts);
            const writable = await handle.createWritable();
            await writable.write(dataBlob);
            await writable.close();
        } catch (err) {
            console.error('File saving failed', err);
        }
    } else {
        // Fallback for browsers that don't support showSaveFilePicker
        const url = window.URL.createObjectURL(dataBlob);
        const a = document.createElement('a');
        a.href = url;
        a.download = filename;
        document.body.appendChild(a);
        a.click();
        a.remove();
    }
}