import { readFileAsString } from '../../utils/readFile';
import readKeyenceCmmFile from './readKeyenceCmmFile';
import { isMitutoyoCmmFile, readMitutoyoCmmFile } from './readMitutoyoCmmFile';
import readZeissCmmFile from './readZeissCmmFile';
import { ReadFileResult } from './types';


export default async function readCmmFile(file: File): Promise<ReadFileResult> {
    let readFileResult: ReadFileResult;
    if (file.name.endsWith('.xlsx') || file.name.endsWith('.xlsm') || file.name.endsWith('.xls')) {
        readFileResult = await readZeissCmmFile(file);
    } else if (file.name.endsWith('.csv')) {
        const fileText = await readFileAsString(file, 'ISO-8859-1');
        if (isMitutoyoCmmFile(fileText)) {
            readFileResult = readMitutoyoCmmFile(fileText);
        } else {
            readFileResult = readKeyenceCmmFile(fileText);
        }
    } else {
        return { success: false, errorMessage: `Invalid file type` };
    }

    if (!readFileResult.errorMessage && readFileResult.measurements?.length == 0) {
        readFileResult.success = false;
        readFileResult.errorMessage = 'No valid measurements found';
    }
    return readFileResult;
}
