import { useState } from 'react';
import { ImportFileResult, ImportFileState, ImportValue, Measurement } from '../services/cmm-import/types';
import Accordion from './accordion';
import ModalSubmitDialog from './modalSubmitDialog';
import ToggleButton from './toggleButton';


interface CmmImportDialogProps {
    isOpen: boolean;
    importResult: ImportFileResult[];
    onCancel: () => void;
    onSubmit: (valuesToImport: Measurement[], sharePointFiles: string[]) => void;
}

export default function CmmImportDialog({
    isOpen,
    importResult,
    onCancel,
    onSubmit,
}: CmmImportDialogProps) {
    const [importState, setImportState] = useState<ImportFileState[]>(importResult);


    const onImportStateChange = (index: number, newImportFileState: ImportFileState) => {
        const newImportState = [...importState];
        newImportState[index] = newImportFileState;
        setImportState(newImportState);
    };

    const handleSubmit = () => {
        const importValues = new Map<string, ImportValue>();
        const sharePointFiles: string[] = [];

        for (const file of importState) {
            if (!file.isIncluded) continue;
            if (file.fileSource === 'sharepoint') {
                sharePointFiles.push(file.filename);
            }

            for (const position of file.positions) {
                if (!position.isIncluded) continue;

                // Later positions overwrite earlier ones, if the same position is selected multiple times
                importValues.set(position.position, {
                    position: position.position,
                    value: position.value,
                    source: file.valueSource,
                    sourceSubType: file.valueSourceSubType,
                });
            }
        }

        const importValuesArray = Array.from(importValues.values());
        onSubmit(importValuesArray, sharePointFiles);
    };

    const getPositionNumberCounts = () => {
        const positionNumberCounts = new Map<string, number>();
        for (const file of importState) {
            if (!file.isIncluded) continue;

            for (const position of file.positions) {
                if (!position.isIncluded) continue;

                const count = positionNumberCounts.get(position.position) || 0;
                positionNumberCounts.set(position.position, count + 1);
            }
        }
        return positionNumberCounts;
    }


    const positionNumberCounts = getPositionNumberCounts();

    if (!isOpen) return null;
    return (
        <ModalSubmitDialog isOpen={isOpen} onCancel={onCancel} onSubmit={handleSubmit}>
            {importState.map((it, index) => (
                <FileImportAccordion
                    key={index}
                    fileIndex={index}
                    importFileResult={it}
                    positionNumberCounts={positionNumberCounts}
                    onChange={onImportStateChange}
                />
            ))}
        </ModalSubmitDialog >
    )
}


function FileImportAccordion({
    fileIndex,
    importFileResult,
    positionNumberCounts,
    onChange,
}: {
    fileIndex: number;
    importFileResult: ImportFileResult;
    positionNumberCounts: Map<string, number>;
    onChange: (index: number, newImportFileResult: ImportFileResult) => void;
}) {

    const handleFileSelectChange = (isSelected: boolean) => {
        const newImportFileResult = structuredClone(importFileResult);
        newImportFileResult.isIncluded = isSelected;
        onChange(fileIndex, newImportFileResult);
    };

    const handlePositionSelectChange = (positionIndex: number, isSelected: boolean) => {
        if (!importFileResult.isIncluded) return;
        const newImportFileResult = structuredClone(importFileResult);
        newImportFileResult.positions[positionIndex].isIncluded = isSelected;
        onChange(fileIndex, newImportFileResult);
    };

    return (
        <Accordion
            style={{ marginBottom: '24px', minWidth: '600px' }}
            initialOpen={importFileResult.isIncluded}
            title={
                <div style={{ margin: '8px', display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                    {importFileResult.filename}
                    <ToggleButton
                        style={{ marginLeft: '12px' }}
                        selection={importFileResult.isIncluded}
                        onSelectionChange={handleFileSelectChange}
                        disabled={!importFileResult.isImportable}
                    />
                </div>
            }
        >
            {/* File import result */}
            {importFileResult.errorMessage
                ? <p className='error' style={{ marginLeft: '20px' }}>{importFileResult.errorMessage}</p>
                : <table className='position-import-table'>
                    <thead>
                        <tr>
                            <th>Position</th>
                            <th>Value</th>
                            <th>Issues</th>
                            <th>Import</th>
                        </tr>
                    </thead>
                    <tbody>

                        {/* Individual position imports*/}
                        {importFileResult.positions?.map((it, index) => {
                            const includedCount = positionNumberCounts.get(it.position) || 0;
                            const issues = includedCount > 1 ? [...it.issues, 'Position selected multiple times'] : it.issues;

                            const isIncluded = importFileResult.isIncluded && it.isIncluded;

                            return (<tr key={index}>
                                <td>{it.position}</td>
                                <td>{it.value}</td>
                                <td style={{ whiteSpace: 'pre-wrap' }}>{issues.join('\n')}</td>
                                <td>
                                    <ToggleButton
                                        selection={isIncluded}
                                        onSelectionChange={(isSelected) => handlePositionSelectChange(index, isSelected)}
                                        disabled={!it.isImportable}
                                    />
                                </td>
                            </tr>)
                        })}

                    </tbody>
                </table>
            }
        </Accordion>
    );
}
