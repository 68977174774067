

export default function countWorkdays(startDate: Date, endDate: Date): number {
    let workdays = 0;
    const currentDay = new Date(startDate);

    while (currentDay <= endDate) {
        const dayOfWeek = currentDay.getDay();
        if (dayOfWeek !== 0 && dayOfWeek !== 6) { // Skip weekends (Sunday = 0, Saturday = 6)
            workdays++;
        }
        currentDay.setDate(currentDay.getDate() + 1);
    }

    return workdays;
}
