import { useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { fetchAppVersion } from '../api/requests';
import * as ReactDOM from 'react-dom'


/**
 * Component that checks if the application has been updated.
 * Prompts the user to refresh the page if the version has changed.
 */
export default function AppVersionCheck() {

    const [portalContainer] = useState(document.createElement('div'));
    const [initialVersion, setInitialVersion] = useState<string | null>(null);
    const [showNotification, setShowNotification] = useState(false);


    const { data: currentVersion } = useQuery({
        queryKey: ['appVersion'],
        queryFn: fetchAppVersion,
        refetchInterval: 60_000,
        refetchOnWindowFocus: false,
    });

    // Check if the version has changed
    useEffect(() => {
        if (!currentVersion) {
            return;
        } else if (!initialVersion) {
            setInitialVersion(currentVersion);
        } else if (initialVersion !== currentVersion) {
            setShowNotification(true);
        }
    }, [currentVersion, initialVersion]);

    // Add portalContainer to DOM
    useEffect(() => {
        if (showNotification) {
            document.body.appendChild(portalContainer);
        }
        return () => {
            if (portalContainer.parentNode) {
                portalContainer.parentNode.removeChild(portalContainer);
            }
        };
    }, [showNotification, portalContainer]);


    if (!showNotification) return null;
    return ReactDOM.createPortal(
        <div className='update-notification' style={{ visibility: 'visible' }}>
            <span>The application has been updated.</span>
            <button onClick={() => window.location.reload()}>Refresh</button>
        </div>,
        portalContainer
    );
}
