import { DefaultError, useMutation, useQuery } from '@tanstack/react-query';
import { fetchMeasurementProtocols, postMeasurement } from '../../api/requests';
import ModalDialog from '../../components/modalDialog';
import { formatDatetime } from '../../utils/fieldFormats';
import { useEffect, useState } from 'react';
import { Measurement, Record } from '../../types/sharedTypeImpl';


interface MeasurementDialogProps {
    isOpen: boolean,
    equipment?: string,
    equipmentDisplayName?: string,
    product?: string,
    inOfflineMode?: boolean,
    onMeasurementAdded: (measurement: Measurement, record?: Record) => void,
    onClose: () => void,
}

export default function MeasurementDialog({
    isOpen,
    equipment,
    equipmentDisplayName,
    product,
    onMeasurementAdded,
    onClose,
}: MeasurementDialogProps) {

    const [filter, setFilter] = useState('');
    const [isExcelMeasurementPending, setIsExcelMeasurementPending] = useState(false);
    const [pendingMeasurementProtocol, setPendingMeasurementProtocol] = useState<string | null>(null);

    const { data: protocols, isLoading } = useQuery({
        queryKey: ['measurement-protocols', product, equipment],
        queryFn: async () => fetchMeasurementProtocols(product, equipment),
        enabled: isOpen,
    });

    const addMeasurement = useMutation({
        mutationFn: postMeasurement,
        onSuccess: (data) => {
            onMeasurementAdded(data.measurement, data.record);
            onClose();
        },
        onError: (err: DefaultError) => {
            console.error(err);
            alert('Failed to add measurement');
        },
        onSettled: () => {
            setIsExcelMeasurementPending(false);
            setPendingMeasurementProtocol(null);
        }
    });

    const filteredProtocols = filter
        ? protocols?.filter(it => {
            const query = filter.toLowerCase().trim();
            return it.protocolName.toLowerCase().includes(query)
                || it.partDisplayName.toLowerCase().includes(query)
                || it.mtLinkiProgramNames.some(it => it.toLowerCase().includes(query))
        })
        : protocols;

    useEffect(() => {
        setFilter('');
    }, [isOpen]);


    const handleSimpleMeasureSelected = async () => {
        if (addMeasurement.isPending) return;
        setIsExcelMeasurementPending(true);
        addMeasurement.mutate({
            equipment,
            product,
            protocolId: null,
        });
    }

    const handleProtocolSelected = async (protocolId: string) => {
        if (addMeasurement.isPending) return;
        setPendingMeasurementProtocol(protocolId);
        addMeasurement.mutate({
            equipment,
            product,
            protocolId,
        });
    }


    const showFilter = protocols?.length >= 10;

    return (
        <ModalDialog
            isOpen={isOpen}
            cancelOnOutsideClick={true}
            onCancel={onClose}
        >
            <p>{equipmentDisplayName} - {product ?? 'Product unknown'}</p>

            {showFilter &&
                <div style={{ paddingBottom: '8px' }}>
                    <span>Filter: </span>
                    <input
                        type='text'
                        value={filter}
                        onChange={e => setFilter(e.target.value)}
                    />
                </div>
            }

            {isLoading
                ? <p>Loading protocols...</p>
                : <table className='table--protocol-selection'>
                    <thead>
                        <tr>
                            <th>Protocol</th>
                            <th>Protocol rev</th>
                            <th>Date created</th>
                            <th>Last measurement</th>
                            <th />
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Excel-mittapöytäkirja</td>
                            <td />
                            <td />
                            <td />
                            <td>
                                <button onClick={handleSimpleMeasureSelected}>
                                    {isExcelMeasurementPending ? 'Adding...' : 'Measure'}
                                </button>
                            </td>
                        </tr>
                        {filteredProtocols?.map(it =>
                            <tr key={it._id}>
                                <td>{it.protocolName}</td>
                                <td>{it.protocolRevision}</td>
                                <td>{formatDatetime(it.createTime)}</td>
                                <td>{it.latestRecord && formatDatetime(it.latestRecord.measurementStart!)}</td>
                                <td>
                                    <button onClick={() => handleProtocolSelected(it._id)} >
                                        {it._id === pendingMeasurementProtocol ? 'Adding...' : 'Measure'}
                                    </button>
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            }
        </ModalDialog>
    );
}
