import { Operation, OperationWithAlarm } from '../../types/sharedTypeImpl';


type OperationDetailsProps = {
    operation: Operation | OperationWithAlarm;
};

export default function OperationDetails({
    operation,
}: OperationDetailsProps) {
    const toolChangeAlarm = (operation as OperationWithAlarm || { toolChangeAlarm: undefined }).toolChangeAlarm;
    const toolChangeIcon = toolChangeAlarm === 'active' ? ' 🔴' : toolChangeAlarm === 'fixed' ? ' ⚪' : '';
    const mainText = operation.details.join(' > ') + toolChangeIcon;

    const collateralList = operation.collateralDamage?.map((it) => `${it.toolVariant} ${it.toolNumber}`);
    const collateralText = collateralList?.length > 0 ? `(Collateral: ${collateralList.join('; ')})` : null;

    return (
        <>
            <span>{mainText}</span>
            {collateralText && (
                <>
                    <br />
                    <span>{collateralText}</span>
                </>
            )}
        </>
    );
}
