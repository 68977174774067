import { useRef, useState } from 'react';
import NavBar from '../../components/navBar';
import FilterableOperationTable from '../../components/operation-table/filterableOperationTable';
import ProductComment from './productComment';
import SessionControlBar from './sessionControlBar';
import SessionsTable, { SessionsTableRef } from './sessionsTable';
import { Session } from '../../types/sharedTypeImpl';
import { SelectedPart } from './selectedPart';


export default function MachineLog() {
    const sessionHistoryTableRef = useRef<SessionsTableRef>();

    const [selectedEquipment, setSelectedEquipment] = useState<string | null>(null);
    const [selectedPart, setSelectedPart] = useState<SelectedPart | null>(null);
    const [selectedSession, setSelectedSession] = useState<Session | null>(null);


    return (
        <>
            <NavBar />
            <div className='content body--tablet'>
                <SessionControlBar
                    selectedEquipment={selectedEquipment}
                    setSelectedEquipment={setSelectedEquipment}
                    selectedPart={selectedPart}
                    setSelectedPart={setSelectedPart}
                />
                <ProductComment selectedPart={selectedPart} />
                <SessionsTable
                    ref={sessionHistoryTableRef}
                    selectedEquipment={selectedEquipment}
                    selectedPart={selectedPart}
                    selectedSession={selectedSession}
                    setSelectedSession={setSelectedSession}
                />
                <FilterableOperationTable
                    session={selectedSession}
                    selectedEquipment={selectedEquipment}
                    onOperationSaved={() => sessionHistoryTableRef.current?.refetchCurrentSessionKpis()}
                />
            </div>
        </>
    )
}
