import { forwardRef, useCallback, useEffect, useImperativeHandle, useRef } from 'react';
import { Point } from '../../types';


export interface ArrowMarkerTrigger {
    updatePosition: () => void;
}

interface ArrowMarkerProps {
    pdfStart?: Point;
    pdfEnd?: Point;
    coordsRef?: React.MutableRefObject<{ start: Point, end: Point } | null>;
    description?: string;
    pdfToScreenCoords: (pdfCoords: Point) => Point;
}

const ArrowMarker = forwardRef<ArrowMarkerTrigger, ArrowMarkerProps>(({
    pdfStart,
    pdfEnd,
    coordsRef,
    description,
    pdfToScreenCoords
}, ref) => {
    const arrowSize = 7;
    const descriptionRef = useRef<HTMLDivElement>(null);
    const lineRef = useRef<SVGLineElement>(null);
    const startArrowRef = useRef<SVGPolygonElement>(null);
    const endArrowRef = useRef<SVGPolygonElement>(null);


    useImperativeHandle(ref, () => ({
        updatePosition,
    }));

    const updatePosition = useCallback(() => {
        if ((!pdfStart || !pdfEnd) && !coordsRef.current) return;

        const p1 = pdfToScreenCoords(pdfStart ?? coordsRef.current?.start);
        const p2 = pdfToScreenCoords(pdfEnd ?? coordsRef.current?.end);
        const angle = calculateAngle(p1.x, p1.y, p2.x, p2.y);

        if (lineRef.current) {
            const [p1f, p2f] = shortenLine(p1, p2, arrowSize / 2);
            lineRef.current.setAttribute('x1', p1f.x.toString());
            lineRef.current.setAttribute('y1', p1f.y.toString());
            lineRef.current.setAttribute('x2', p2f.x.toString());
            lineRef.current.setAttribute('y2', p2f.y.toString());
        }

        if (startArrowRef.current) {
            startArrowRef.current.setAttribute('transform', `translate(${p1.x},${p1.y}) rotate(${angle - 90})`);
        }

        if (endArrowRef.current) {
            endArrowRef.current.setAttribute('transform', `translate(${p2.x},${p2.y}) rotate(${angle + 90})`);
        }

        if (descriptionRef.current) {
            const topPoint = (p1.y < p2.y) ? p1 : p2;
            descriptionRef.current.style.left = `${topPoint.x}px`;
            descriptionRef.current.style.top = `${topPoint.y}px`;
        }

        requestAnimationFrame(() => { });
    }, [pdfStart, pdfEnd, coordsRef, pdfToScreenCoords]);

    //Calls updatePosition when one of the props changes
    useEffect(() => {
        updatePosition();
    }, [updatePosition]);

    const calculateAngle = (x1: number, y1: number, x2: number, y2: number): number => {
        return Math.atan2(y2 - y1, x2 - x1) * (180 / Math.PI);
    };

    const shortenLine = (p1: Point, p2: Point, shortenBy: number): [Point, Point] => {
        const dx = p2.x - p1.x;
        const dy = p2.y - p1.y;
        const length = Math.sqrt(dx * dx + dy * dy);

        const unitVector = { x: dx / length, y: dy / length };

        return [
            { x: p1.x + unitVector.x * shortenBy, y: p1.y + unitVector.y * shortenBy },
            { x: p2.x - unitVector.x * shortenBy, y: p2.y - unitVector.y * shortenBy },
        ];
    };

    const arrowPoints = [
        `0,0`, // tip of the arrow at the origin
        `${arrowSize},${1.5 * arrowSize}`,
        `0,${arrowSize}`,
        `-${arrowSize},${1.5 * arrowSize}`
    ].join(' ');

    return (
        <div style={{
            position: 'absolute',
            overflow: 'hidden',
            left: 0,
            top: 0,
            width: '100%',
            height: '100%',
        }}>
            <svg style={{
                position: 'absolute',
                left: 0,
                top: 0,
                border: 'none',
                width: '100%',
                height: '100%',
                pointerEvents: 'none' // Ensures right click events pass through
            }}>
                <line
                    ref={lineRef}
                    stroke='red'
                    strokeWidth='3'
                />
                <polygon
                    ref={startArrowRef}
                    points={arrowPoints}
                    fill='red'
                />
                <polygon
                    ref={endArrowRef}
                    points={arrowPoints}
                    fill='red'
                />
            </svg>


            {description &&
                <div
                    ref={descriptionRef}
                    style={{
                        width: 'max-content',
                        maxWidth: '400px',
                        position: 'absolute',
                        transform: 'translate(20px, -130%)',
                        background: '#fff',
                        border: '2px solid #f00',
                        borderRadius: '5px',
                        padding: '8px'
                    }}>
                    {description}
                </div>
            }
        </div>
    );
});

export default ArrowMarker;
