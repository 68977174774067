

export default function Input(props: React.InputHTMLAttributes<HTMLInputElement>) {

    const preventScroll = (event: React.WheelEvent<HTMLInputElement>) => {
        if (event.currentTarget.type === 'number') {
            event.currentTarget.blur();//Keep values from being changed, but allow scrolling
        }
        if (props.onWheel) {
            props.onWheel(event);
        }
    };

    return (
        <input
            {...props}
            onWheel={preventScroll}
            style={{
                ...props.style,
            }} />
    );
}
