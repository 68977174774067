import { useMutation } from '@tanstack/react-query';
import { postOperation, updateOperation } from '../../api/requests';
import ModalDialog from '../modalDialog';
import { OperationFormResult } from './operationDraft';
import OperationForm from './operationForm';
import { NewOperation, Operation, Session } from '../../types/sharedTypeImpl';
import { logError } from '../../utils/errorLogging';


interface OperationDialogProps {
    isOpen: boolean;
    session?: Session | null;
    otherOperations?: Operation[];
    operationUnderEdit?: Operation | null;
    onClose: () => void;
    onOperationSaved: (operation: Operation) => void;
}

export default function OperationDialog({
    isOpen,
    session,
    otherOperations,
    operationUnderEdit,
    onClose,
    onOperationSaved,
}: OperationDialogProps) {

    const operationSave = useMutation({
        mutationFn: async (operation: NewOperation | Operation) => (operation as any)._id
            ? await updateOperation(operation as Operation)
            : await postOperation(operation as NewOperation),
        onSuccess: (savedOperation: Operation) => {
            onOperationSaved(savedOperation);
            onClose();
        }
    });

    const handleOperationSubmit = (operationFormResult: OperationFormResult) => {
        const newOperation: NewOperation | Operation = {
            equipment: session?.equipment,
            product: session?.product,
            ...operationFormResult,
            ...(operationUnderEdit && {
                _id: operationUnderEdit._id,
                equipment: operationUnderEdit.equipment,
                product: operationUnderEdit.product,
                group: operationUnderEdit.group,
                time: operationUnderEdit.time,
                partCount: operationUnderEdit.partCount,
                scrapLabel: operationUnderEdit.scrapLabel,
                cycleTimeOverall: operationUnderEdit.cycleTimeOverall,
                cycleTimeRecent: operationUnderEdit.cycleTimeRecent,
            }),
        };
        if (newOperation.potentialDiscards == null || newOperation.potentialDiscards === 0) {
            delete (newOperation as Operation).scrapLabel;
        }

        operationSave.mutate(newOperation);
    }



    if (!isOpen) return null;
    if (!session && !operationUnderEdit) {
        logError('Session is required for new operations');
        return null;
    }

    return (
        <ModalDialog
            isOpen={isOpen}
            onCancel={onClose}
            frameClassName='modal-frame--operation-dialog'
            contentClassName='modal-content--tablet'
        >
            <OperationForm
                session={session}
                operationUnderEdit={operationUnderEdit}
                otherOperations={otherOperations}
                onOperationSubmit={handleOperationSubmit}
                isSaving={operationSave.isPending}
            />
        </ModalDialog>
    )
}
