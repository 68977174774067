import { forwardRef, useImperativeHandle, useRef } from 'react';
import { OpSubFormProps, OpSubFormRef } from './operationSubForm';
import { MachineComponentOptions, MachineMainComponentOptions, DimensionOptions, OperationTypeOptions, PlusOrMinusOptions, ToolBreakReasonOptions, IsScheduledOptions, ToolFailReasonOptions, ToolVariantTypeOptions } from './operationFormshape';


export const OperationType = forwardRef<OpSubFormRef, OpSubFormProps>(({
    operation,
    onOperationChange,
}: OpSubFormProps, ref) => {
    const inputRef = useRef<SelectSubFormRef>(null);

    useImperativeHandle(ref, () => ({
        validateWarnAndGet: (result) => {
            if (!inputRef.current.validateAndWarn()) return false;
            result.operationType = operation.operationType;
            result.details = [...(result.details ?? []), operation.operationType];
            return true;
        }
    }));

    return (
        <SelectSubForm
            ref={inputRef}
            group={'operationType'}
            options={Object.values(OperationTypeOptions)}
            value={operation.operationType}
            onChange={(value) => onOperationChange({ ...operation, operationType: value })}
        />
    );
});


export const MachineComponent = forwardRef<OpSubFormRef, OpSubFormProps>(({
    operation,
    onOperationChange,
}: OpSubFormProps, ref) => {
    const inputRef = useRef<SelectSubFormRef>(null);

    useImperativeHandle(ref, () => ({
        validateWarnAndGet: (result) => {
            if (!inputRef.current.validateAndWarn()) return false;
            result.machineComponent = operation.machineComponent;
            result.details = [...(result.details ?? []), operation.machineComponent];
            return true;
        }
    }));

    return (
        <SelectSubForm
            ref={inputRef}
            group={'machineComponent'}
            options={Object.values(MachineComponentOptions)}
            value={operation.machineComponent}
            onChange={(value) => onOperationChange({ ...operation, machineComponent: value })}
        />
    );
});


export const MachineMainComponent = forwardRef<OpSubFormRef, OpSubFormProps>(({
    operation,
    onOperationChange,
}: OpSubFormProps, ref) => {
    const inputRef = useRef<SelectSubFormRef>(null);

    useImperativeHandle(ref, () => ({
        validateWarnAndGet: (result) => {
            if (!inputRef.current.validateAndWarn()) return false;
            result.machineMainComponent = operation.machineMainComponent;
            result.details = [...(result.details ?? []), operation.machineMainComponent];
            return true;
        }
    }));

    return (
        <SelectSubForm
            ref={inputRef}
            group={'machineMainComponent'}
            options={Object.values(MachineMainComponentOptions)}
            value={operation.machineMainComponent}
            onChange={(value) => onOperationChange({ ...operation, machineMainComponent: value })}
        />
    );
});


export const PlusOrMinus = forwardRef<OpSubFormRef, OpSubFormProps>(({
    operation,
    onOperationChange,
}: OpSubFormProps, ref) => {
    const inputRef = useRef<SelectSubFormRef>(null);

    useImperativeHandle(ref, () => ({
        validateWarnAndGet: (result) => {
            if (!inputRef.current.validateAndWarn()) return false;
            result.plusOrMinus = operation.plusOrMinus;
            result.details = [...(result.details ?? []), operation.plusOrMinus];
            return true;
        }
    }));

    return (
        <SelectSubForm
            ref={inputRef}
            group={'plusOrMinus'}
            options={Object.values(PlusOrMinusOptions)}
            value={operation.plusOrMinus}
            onChange={(value) => onOperationChange({ ...operation, plusOrMinus: value })}
        />
    );
});


export const ToolVariant = forwardRef<OpSubFormRef, OpSubFormProps>(({
    operation,
    onOperationChange,
}: OpSubFormProps, ref) => {
    const inputRef = useRef<SelectSubFormRef>(null);

    useImperativeHandle(ref, () => ({
        validateWarnAndGet: (result) => {
            if (!inputRef.current.validateAndWarn()) return false;
            result.toolVariant = operation.toolVariant;
            result.details = [...(result.details ?? []), operation.toolVariant];
            return true;
        }
    }));

    return (
        <SelectSubForm
            ref={inputRef}
            group={'toolVariant'}
            options={Object.values(ToolVariantTypeOptions)}
            value={operation.toolVariant}
            onChange={(value) => onOperationChange({ ...operation, toolVariant: value })}
        />
    );
});


export const Dimension = forwardRef<OpSubFormRef, OpSubFormProps>(({
    operation,
    onOperationChange,
}: OpSubFormProps, ref) => {
    const inputRef = useRef<SelectSubFormRef>(null);

    useImperativeHandle(ref, () => ({
        validateWarnAndGet: (result) => {
            if (!inputRef.current.validateAndWarn()) return false;
            result.dimension = operation.dimension;
            result.details = [...(result.details ?? []), operation.dimension];
            return true;
        }
    }));

    return (
        <SelectSubForm
            ref={inputRef}
            group={'dimension'}
            options={Object.values(DimensionOptions)}
            value={operation.dimension}
            onChange={(value) => onOperationChange({ ...operation, dimension: value })}
        />
    );
});


export const IsScheduled = forwardRef<OpSubFormRef, OpSubFormProps>(({
    operation,
    onOperationChange,
}: OpSubFormProps, ref) => {
    const inputRef = useRef<SelectSubFormRef>(null);

    useImperativeHandle(ref, () => ({
        validateWarnAndGet: (result) => {
            if (!inputRef.current.validateAndWarn()) return false;
            result.isScheduled = operation.isScheduled;
            result.details = [...(result.details ?? []), operation.isScheduled];
            return true;
        }
    }));

    return (
        <SelectSubForm
            ref={inputRef}
            group={'isScheduled'}
            options={Object.values(IsScheduledOptions)}
            value={operation.isScheduled}
            onChange={(value) => onOperationChange({ ...operation, isScheduled: value })}
        />
    );
});


export const ToolFailReason = forwardRef<OpSubFormRef, OpSubFormProps>(({
    operation,
    onOperationChange,
}: OpSubFormProps, ref) => {
    const inputRef = useRef<SelectSubFormRef>(null);

    useImperativeHandle(ref, () => ({
        validateWarnAndGet: (result) => {
            if (!inputRef.current.validateAndWarn()) return false;
            result.toolFailReason = operation.toolFailReason;
            result.details = [...(result.details ?? []), operation.toolFailReason];
            return true;
        }
    }));

    return (
        <SelectSubForm
            ref={inputRef}
            group={'toolFailReason'}
            options={Object.values(ToolFailReasonOptions)}
            value={operation.toolFailReason}
            onChange={(value) => onOperationChange({ ...operation, toolFailReason: value })}
        />
    );
});


export const ToolBreakReason = forwardRef<OpSubFormRef, OpSubFormProps>(({
    operation,
    onOperationChange,
}: OpSubFormProps, ref) => {
    const inputRef = useRef<SelectSubFormRef>(null);

    useImperativeHandle(ref, () => ({
        validateWarnAndGet: (result) => {
            if (!inputRef.current.validateAndWarn()) return false;
            result.toolBreakReason = operation.toolBreakReason;
            result.details = [...(result.details ?? []), operation.toolBreakReason];
            return true;
        }
    }));

    return (
        <SelectSubForm
            ref={inputRef}
            group={'toolBreakReason'}
            options={Object.values(ToolBreakReasonOptions)}
            value={operation.toolBreakReason}
            onChange={(value) => onOperationChange({ ...operation, toolBreakReason: value })}
        />
    );
});


interface SelectSubFormRef {
    validateAndWarn: () => boolean;
}

interface SelectSubFormProps {
    group: string;
    options: string[];
    value: string;
    onChange: (value: string) => void;
}

const SelectSubForm = forwardRef<SelectSubFormRef, SelectSubFormProps>(({
    group,
    options,
    value,
    onChange,
}, ref) => {

    useImperativeHandle(ref, () => ({
        validateAndWarn: () => true,//Html required attribute will handle this
    }));

    return (
        <fieldset className='dialog-form__fieldset'>
            {options.map(it => (
                <label key={it}>
                    <input
                        type='radio'
                        name={group}
                        value={it}
                        required
                        checked={it === value}
                        onChange={() => onChange(it)}
                    />
                    {it}
                </label>
            ))}
        </fieldset>
    );
});
