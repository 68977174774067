import { useState } from 'react';
import NavBar from '../../components/navBar';
import BrokenToolsPerSession from './brokenToolsPerSession';
import BrokenToolsPerTool from './brokenToolsPerTool';
import BrokenToolOperations from './brokenToolOperations';
import { Operation, Session } from '../../types/sharedTypeImpl';
import { Tool } from '../../components/operation-dialog/operationDraft';


export default function BrokenTools() {
    const [selectedSession, setSelectedSession] = useState<Session | null>(null);
    const [selectedSessionOps, setSelectedSessionOps] = useState<Operation[] | null>(null);
    const [selectedTool, setSelectedTool] = useState<Tool | null>(null);
    const [selectedToolOps, setSelectedToolOps] = useState<Operation[] | null>(null);

    const handleSessionSelected = (session: Session, sessionOps: Operation[]) => {
        setSelectedSession(session);
        setSelectedSessionOps(sessionOps);
        setSelectedTool(null);
        setSelectedToolOps(null);
    };

    const handleToolSelected = (tool: Tool, toolOps: Operation[]) => {
        setSelectedTool(tool);
        setSelectedToolOps(toolOps);
    };

    return (
        <>
            <NavBar />
            <div className='content' style={{ maxWidth: '1280px' }}>
                <BrokenToolsPerSession
                    selectedSession={selectedSession}
                    onSessionSelected={handleSessionSelected}
                />

                {selectedSession && (
                    <BrokenToolsPerTool
                        session={selectedSession}
                        sessionOperations={selectedSessionOps}
                        selectedTool={selectedTool}
                        onToolSelected={handleToolSelected}
                    />
                )}

                {selectedToolOps && (
                    <BrokenToolOperations
                        session={selectedSession}
                        toolOperations={selectedToolOps}
                    />
                )}
            </div>
        </>
    );
}
