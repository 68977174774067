import { PreviewTable } from './types';


export default function exportPreviewToCsv(previewTable: PreviewTable): string {
    const headerRow = [''];
    headerRow.push(...previewTable.positionNumbers);

    const nominalRow = ['Nominal'];
    nominalRow.push(...previewTable.nominalValues);

    const valueRows = [];
    for (const recordRow of previewTable.recordRows) {
        if (!recordRow.includeInReport) continue;

        const row = [recordRow.partCount.toString()];
        for (const value of recordRow.values) {
            row.push(value.value);
        }
        valueRows.push(row);
    }

    const csvTable = [headerRow, nominalRow, ...valueRows];
    const csv = csvTable.map(row => row.join(',')).join('\n');
    return csv;
}
