import * as ReactDOM from 'react-dom'
import { MouseEvent, ReactNode, useEffect, useState } from 'react'


interface ModalDialogProps {
    isOpen: boolean;
    children: ReactNode;
    frameClassName?: string;
    frameStyle?: React.CSSProperties;
    contentClassName?: string;
    contentStyle?: React.CSSProperties;
    onCancel?: () => void;
    showCloseX?: boolean;
    cancelOnOutsideClick?: boolean;
}

export default function ModalDialog({
    isOpen,
    children,
    frameClassName,
    frameStyle,
    contentClassName,
    contentStyle,
    onCancel,
    showCloseX = true,
    cancelOnOutsideClick = false,
}: ModalDialogProps) {
    const [portalContainer] = useState(document.createElement('div'));


    function handleEscapeKeyPress(event: KeyboardEvent) {
        if (event.key === 'Escape') {
            onCancel?.();
        }
    }

    // Handling click outside the modal frame
    function handleOutsideClick(event: MouseEvent<HTMLDivElement>) {
        if ((event.target as Element).classList.contains('modal-background')) {
            onCancel?.();
        }
    }

    useEffect(() => {
        // Add element to DOM
        if (isOpen) {
            document.body.appendChild(portalContainer);
            if (cancelOnOutsideClick) {
                document.addEventListener('keydown', handleEscapeKeyPress);
            }
        }
        return () => {
            if (portalContainer.parentNode) {
                portalContainer.parentNode.removeChild(portalContainer);
            }
            document.removeEventListener('keydown', handleEscapeKeyPress);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpen, cancelOnOutsideClick, onCancel, portalContainer]);


    if (!isOpen) return null;
    return ReactDOM.createPortal(
        <div
            className='modal-background'
            style={{ display: 'flex', flexDirection: 'column' }}
            onClick={cancelOnOutsideClick ? handleOutsideClick : undefined}
        >
            <div className={`modal-frame ${frameClassName}`} style={frameStyle} onClick={e => e.stopPropagation()}>
                {showCloseX && (
                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <span className='close' onClick={onCancel}>&times;</span>
                    </div>
                )}
                <div className={contentClassName} style={contentStyle}>
                    {children}
                </div>
            </div>
        </div>,
        portalContainer
    );
}
