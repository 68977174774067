import { ContextMenu, MenuItem } from '../../components/contextMenu';
import { Point } from '../../types';
import { Record } from '../../types/sharedTypeImpl';


export interface RecordContextMenuState {
    record: Record;
    screenCoords: Point;
}


interface RecordContextMenuProps {
    record: Record;
    screenCoords: Point;
    onDeleteSelected: (record: Record) => void;
    onEditSelected: (record: Record) => void;
    onExportSelected: (record: Record) => void;
    onClose: () => void;
}

export default function RecordContextMenu({
    record,
    screenCoords,
    onDeleteSelected,
    onEditSelected,
    onExportSelected,
    onClose,
}: RecordContextMenuProps) {

    const menuItems: MenuItem[] = [
        {
            text: 'Delete',
            isEnabled: record.isDraft,
            callback: () => onDeleteSelected(record),
        },
        {
            text: 'Edit',
            isEnabled: !record.isDraft,
            callback: () => onEditSelected(record),
        },
        {
            text: 'Export',
            isEnabled: !record.isDraft,
            callback: () => onExportSelected(record),
        },
    ];

    return (
        <ContextMenu
            isOpen={true}
            position={screenCoords}
            items={menuItems}
            close={onClose}
        />
    );
}
