

export function dateWithoutTime(dateValue: Date | string): Date {
    const date = new Date(dateValue);
    return new Date(Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate()));
}

export function dateToLabel(date: Date): string {
    // const options = { weekday: 'long', month: 'long', day: '2-digit' };
    const options: Intl.DateTimeFormatOptions = { month: 'long', day: '2-digit' };
    const formatted = new Intl.DateTimeFormat('en-US', options).format(date);
    return formatted;
}
