import { ReactNode } from 'react';
import ModalDialog from './modalDialog';


interface ModalSubmitDialogProps {
    isOpen: boolean;
    isSubmitting?: boolean;
    children: ReactNode;
    frameClassName?: string;
    frameStyle?: React.CSSProperties;
    contentClassName?: string;
    contentStyle?: React.CSSProperties;
    onCancel: () => void;
    onSubmit: () => void;
}

export default function ModalSubmitDialog({
    isOpen,
    isSubmitting,
    children,
    frameClassName,
    frameStyle,
    contentClassName,
    contentStyle,
    onCancel,
    onSubmit,
}: ModalSubmitDialogProps) {

    const buttonsDisabled = isSubmitting || false;

    return (
        <ModalDialog
            isOpen={isOpen}
            frameClassName={frameClassName}
            frameStyle={frameStyle}
            contentClassName={contentClassName}
            contentStyle={contentStyle}
            onCancel={onCancel}
        >
            {children}

            <div className='dialog--button-bar'>
                <button onClick={onCancel} disabled={buttonsDisabled}>Cancel</button>
                <button onClick={onSubmit} disabled={buttonsDisabled}>
                    {isSubmitting ? 'Submitting...' : 'Submit'}
                </button>
            </div>
        </ModalDialog>
    )
}
