import { DiagnosticsData } from './diagnosticsData';
import { formatDuration, formatLatestData } from '../../utils/fieldFormats';
import getStatusMarker from '../../utils/getStatusMarker';


interface DiagnosticsTableProps {
    diagnosticsResults: DiagnosticsData;
    style?: React.CSSProperties;
}

export default function DiagnosticsTable({
    diagnosticsResults,
    style,
}: DiagnosticsTableProps) {
    return (
        <table style={{ ...style, width: '1080px' }}>
            <tbody>
                <tr>
                    <td />
                    <td>Server uptime</td>
                    <td>{formatDuration(diagnosticsResults.serverUptime, true, false)}</td>
                </tr>
                <tr>
                    <td>{getStatusMarker(diagnosticsResults.startupCompleteStatus)}</td>
                    <td>Startup complete</td>
                    <td>{diagnosticsResults.startupComplete ? 'True' : 'False'}</td>
                </tr>
                <tr>
                    <td>{getStatusMarker(diagnosticsResults.mtLinkiPingStatus)}</td>
                    <td>MT-LINKi server</td>
                    <td>{diagnosticsResults.mtLinkiPingMessage}</td>
                </tr>
                <tr>
                    <td>{getStatusMarker(diagnosticsResults.mtLinkiApiStatus)}</td>
                    <td>MT-LINKi API</td>
                    <td>{diagnosticsResults.mtLinkiApiMessage}</td>
                </tr>
                <tr>
                    <td>{getStatusMarker(diagnosticsResults.mtLinkiSpeedStatus)}</td>
                    <td>MT-LINKi response time</td>
                    <td>{
                        diagnosticsResults.mtLinkiSpeedTimingOut ? 'Timing out'
                            : !diagnosticsResults.mtLinkiSpeedAvgTime ? 'No data'
                                : formatDuration(diagnosticsResults.mtLinkiSpeedAvgTime!, false, true)
                    }</td>
                </tr>
                <tr>
                    <td>{getStatusMarker(diagnosticsResults.fullUpdateStatus)}</td>
                    <td>Measurement Interval updater</td>
                    <td>{
                        diagnosticsResults.latestFullUpdate
                            ? `Latest: ${formatLatestData(diagnosticsResults.latestFullUpdate)}`
                            : 'No data'
                    }</td>
                </tr>
                <tr>
                    <td>{getStatusMarker(diagnosticsResults.fastUpdateStatus)}</td>
                    <td>Overlook updater</td>
                    <td>{
                        diagnosticsResults.latestFastUpdate
                            ? `Latest: ${formatLatestData(diagnosticsResults.latestFastUpdate)}`
                            : 'No data'
                    }</td>
                </tr>
                <tr>
                    <td colSpan={3}>{diagnosticsResults.conclusion}</td>
                </tr>
            </tbody>
        </table>
    );
}
