import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import { deleteRecordWithRelated, fetchRecords } from '../../api/requests';
import LinkButton from '../../components/linkButton';
import NavBar from '../../components/navBar';
import RecordListTable from './recordListTable';
import RecordsReportDialog from '../../components/records-report/recordsReportDialog';
import { Record } from '../../types/sharedTypeImpl';


export default function RecordList() {
    const defaultLimit = 200;
    const [maxRecords, setMaxRecords] = useState(defaultLimit);
    const [filterInput, setFilterInput] = useState('');
    const [onlyWithIssues, setOnlyWithIssues] = useState(false);
    const [exportOrderNumber, setExportOrderNumber] = useState<string | undefined>(undefined);


    const { data: records, error, isLoading, refetch } = useQuery({
        queryKey: ['records', maxRecords, filterInput, onlyWithIssues],
        queryFn: async () => await fetchRecords({
            search: filterInput,
            limit: maxRecords,
            onlyWithIssues,
        }),
        placeholderData: (prev) => prev,
    });


    const handleClearFilters = () => {
        setFilterInput('');
        setOnlyWithIssues(false);
    }

    const handleRecordDelete = async (record: Record) => {
        const description = `${record.equipmentDisplayName} - ${record.product} - ${record.protocolName}`;
        if (window.confirm(`Are you sure you want to delete the following record?\n${description}`)) {
            try {
                await deleteRecordWithRelated(record);
                refetch();
            } catch (error) {
                alert('Failed to delete record');
            }
        }
    }

    const handleRecordsExport = async function (record: Record) {
        if (!record.orderNumber) {
            alert('Cannot export records without an order number');
            return;
        }
        setExportOrderNumber(record.orderNumber);
    };

    const handleLoadMore = () => {
        setMaxRecords(maxRecords + defaultLimit);
    }


    const showLoadMore = records != null && records?.length >= maxRecords;

    return (
        <>
            <NavBar />
            <div className='content' style={{ width: '1480px' }}>
                <div style={{ display: 'flex', justifyContent: 'end', alignItems: 'center' }}>
                    <label className='with-checkbox '>
                        Show issues only
                        <input
                            style={{ marginLeft: '8px' }}
                            type='checkbox'
                            checked={onlyWithIssues}
                            onChange={e => setOnlyWithIssues(e.target.checked)}
                        />
                    </label>
                    <input
                        style={{ marginLeft: '18px' }}
                        type='text'
                        placeholder='Search'
                        value={filterInput}
                        onChange={e => setFilterInput(e.target.value)}
                    />
                    <button className='button--clear-filters' onClick={(e) => { e.stopPropagation(); handleClearFilters(); }}>
                        <img src='/clear-filter-icon.svg' alt='Clear filters' />
                    </button>
                </div >

                <RecordListTable
                    isLoading={isLoading}
                    error={error}
                    records={records}
                    onRecordDelete={handleRecordDelete}
                    onRecordsExport={handleRecordsExport}
                    onSearchTextChange={setFilterInput}
                />

                <LinkButton
                    style={{ display: 'flex', justifyContent: 'center', marginTop: '10px', marginBottom: '20px', visibility: showLoadMore ? 'visible' : 'hidden' }}
                    onClick={handleLoadMore}>
                    Load more
                </LinkButton>
            </div>

            <RecordsReportDialog
                isOpen={exportOrderNumber != null}
                orderNumber={exportOrderNumber}
                onClose={() => setExportOrderNumber(undefined)}
            />
        </>
    );
}
